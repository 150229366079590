import React from "react";
import { useSelector } from "react-redux";
import { appliedTableHeading } from "../constants";
import { ResizableTable } from "../../../commons/resizabletable/ResizableTable";
import i18n from "../../../../utilities/i18n";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import { checkPermission, currencyFormat } from "../../../../utilities/commonUtilities";
import { Divider } from "@mui/material";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { useDispatch } from "react-redux";
import { handlePatientTableDataUpdate, handleProcedureEditWindowClose, hideModalWindow, onHandleClaimIDChange, openPatientModalWindow, removePatientClaimFromTable, resetOpenedClaimId, setOpenedClaimId, showNotification } from "../StateManagement/paymentSlice";
// import SelectInput from "../../../commons/input/SelectInput";
import Label from "../../../commons/Label";
import { getpatientClaimList, getPatientPaymentClaimDetails } from "../StateManagement/asyncThunkAPI";
import { DEFAULT_PAGING_SIZE, ROUTE_SEARCH_CLAIMS } from "../../../../utilities/staticConfigs";
import { IconButton } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CustomizedDialogs from "../../../modalWindowComponent/CustomizedDialogs";
import PatientProcedureList from "./PatientProcedureList";
import CustomizedSmallDialogs from "../../../modalWindowComponent/CustomisedSmallDialog";
import service1 from "../../../patientManagement/patients/service"
import { getStorage } from "../../../../utilities/browserStorage";
import { permission_key_values_claim } from "../../../../utilities/permissions";
const loaderStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '50vh',
    fontFamily: 'Arial, sans-serif'
};

const spinnerStyle = {
    border: '16px solid #f3f3f3',
    borderTop: '16px solid #3498db',
    borderRadius: '50%',
    width: '80px',
    height: '80px',
    animation: 'spin 2s linear infinite'
};

const textStyle = {
    fontSize: '20px',
    fontFamily: 'lato',
    marginTop: '5px'
};

const tableHeadings = [
    i18n.t('payments.eraPage.table2.claimId'),
    i18n.t('payments.eraPage.table2.charges'),
    "Receipts",
    "Balance",
    "Total Adjustment",
    "Total Apply Amount",
    i18n.t('payments.eraPage.table2.closingBalance'),
    "Actions"
];
const PatientClaimList = (props) => {
    const dispatch = useDispatch();
    const { postManually, showPreview, autoPost, showAppliedPayments,
        showContinuePosting, unAppliedAmount, sumApplied
    } = useSelector((state) => state.paymentData.patientPostDetails);
    const { patientAppliedProcedures, masterClaims, selectedClaimVals, selectClaim, validationError,patientClaims, dataLoading, openClaimWindow, claimIdOpened, validationErrorModal,openedClaimId
    } = useSelector((state) => state.paymentData);

    const onSearchInsurances = (query) => {
        dispatch(getpatientClaimList({ query: query, patientPK: props.patientPK }));
    }

    const onHandleClaimChange = (e) => {
        if (e.length > 0) {
            dispatch(onHandleClaimIDChange(e))
            dispatch(getPatientPaymentClaimDetails({ pageSize: DEFAULT_PAGING_SIZE, page: 1, patientPK: props.patientPK }))
        }
        else {
            dispatch(onHandleClaimIDChange([]))
        }
    }

    function selectOnTab(e) {
        if (e.key === "Tab") {
            document.querySelector("a.dropdown-item.active").click();
        }
    }

    function claimRedirection(e, item) {
        e.preventDefault();
        if (!checkPermission(permission_key_values_claim.claims_search_claims_view)) {
            dispatch(showNotification({ message: i18n.t('errorMessages.permission_error'), tag: "error" }))
            return;
        }
        let openedPKs = false;
        const result = service1.GetSelectedTabs("claims", getStorage("practice"));
        result.then((response) => {
            openedPKs =
                response.data && response.data.opened_tab_pks
                    ? response.data.opened_tab_pks
                    : [];

            let allowedPk = ["search", "last10"];
            allowedPk.forEach((itemData) => {
                let index = openedPKs.indexOf(itemData);
                if (index !== -1) {
                    openedPKs.splice(index, 1);
                }
            });

            let redirect_item = {
                pk: item.claim_id,
                claim_id: item.custom_claim_id,
                type: "claims",
                action: "add",
                practice_pk: getStorage("practice"),
            };
            
            if (openedClaimId && openedClaimId.length >= 1) {
                dispatch(showNotification({ message: i18n.t("errorMessages.max_claim_tabs"), tag: "error" }))
                return;
            }
            const resultData = service1.AddRemoveSelectedTab(redirect_item);
            resultData.then((responseData) => {
                if (responseData.status === 200 || responseData.data === 201) {
                    dispatch(setOpenedClaimId([item.claim_id]));
                    const newWindow = window.open(ROUTE_SEARCH_CLAIMS, "_blank");

                    const intervalId = setInterval(() => {
                        if (newWindow && newWindow?.closed) { 
                            dispatch(resetOpenedClaimId());
                            clearInterval(intervalId); 
                        }
                    }, 1000);

                } else if (responseData?.data && responseData?.data?.code === 409) {
                    dispatch(showNotification({ message: i18n.t("errorMessages.max_claim_tabs"), tag: "error" }))
                }
            });
        });
    }

    const onAlertOk = () => {
        dispatch(hideModalWindow({ key: "validationErrorModal", open: false }))
    }


    // const onHandleChange = (e) => {
    //     let value = e.target.value
    //     let name = e.target.name;
    //     dispatch(updateValueChange({ name: name, value: value, key: "patientPaymentVariables" }))
    //     if (name === "selectCharge") {
    //         dispatch(getPatientPaidClaims({ pageSize: DEFAULT_PAGING_SIZE, page: 1, patientPK: props.patientPK }))
    //     }
    // }

    //Commented the since its not being called anywhere, may use it later if needed.
    //eslint-disable-next-line
    // const onHandleApply = (e, item, apply_type) => {
    //     let value = String(e.target.value).trim()
    //         .replace(/[^0-9.]/g, '')  // Remove all non-numeric and non-period characters
    //         .replace(/(\..*?)\./g, '$1')  // Remove all extra decimal points
    //         .replace(/(\.\d{2})\d*/g, '$1')  // Allow only two digits after the first decimal point
    //         .substring(0, 9);  // Limit the length to 9 characters

    //     dispatch(onHandleApplyPaient({ value: value, item: item, apply_type: apply_type }));
    // }


    function getRowClassName(index) {
        let classNames = '';
        if (index % 2 === 0) {
            classNames += 'procedureEditedTableRow ';
        } else {
            classNames += 'row-bg-change-last-opened-indication ';
        }
        return classNames.trim();
    }

    const handleProcedureEditSubmit = () => {
        dispatch(handlePatientTableDataUpdate())
    }

    const showtableData = (isEdited, value, changedValue) => {
        return (
            <>
                {isEdited ?
                    <>
                        <span style={{ textDecoration: 'line-through', fontSize: 'smaller' }}>
                            {currencyFormat(value)}
                        </span>
                        {' '}
                        <span>
                            {currencyFormat(changedValue)}
                        </span>
                    </>
                    :
                    currencyFormat(value)
                }
            </>
        )
    }

    return (
        <>
            {postManually || (autoPost && showPreview) ? (
                <div>
                    {showAppliedPayments && (
                        <div className="padding-top20">
                            <table className="table">
                                <thead>
                                    <tr>
                                        {appliedTableHeading &&
                                            appliedTableHeading.map((item, index) => {
                                                return <th key={index}>{item}</th>;
                                            })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {(!patientAppliedProcedures ||
                                        patientAppliedProcedures.length === 0) && (
                                            <tr>
                                                <td align="center" colSpan={8}>
                                                    {i18n.t("commons.noRecords")}{" "}
                                                </td>
                                            </tr>
                                        )}
                                    {patientAppliedProcedures &&
                                        patientAppliedProcedures.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td className="width-125px" align="center">
                                                        <Link className="p-0" to="#" onClick={(e) => claimRedirection(e, item)}>
                                                            {item.custom_claim_id}
                                                        </Link>
                                                    </td>
                                                    <td className="width-125px" align="center">
                                                        {item.service_from_date
                                                            ? format(
                                                                new Date(item.service_from_date),
                                                                "MM/dd/yyyy"
                                                            )
                                                            : ""}
                                                    </td>
                                                    <td className="width-125px" align="center">
                                                        {item.procedure}
                                                    </td>
                                                    <td className="small" align="right">
                                                        {currencyFormat(item.charges)}
                                                    </td>
                                                    <td className="small" align="right">
                                                        {currencyFormat(item.apply_payment)}
                                                    </td>
                                                    <td className="small" align="right">
                                                        {currencyFormat(item.apply_adjustments)}
                                                    </td>
                                                    <td className="small" align="right">
                                                        {currencyFormat(item.balance)}
                                                    </td>
                                                    <td align="center">
                                                        {item.responsibility_type_name}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </table>
                        </div>
                    )}
                    {showContinuePosting && (
                        <div>
                            <Divider style={{ color: '#000', marginTop: 20, marginBottom: 20 }} />
                            <div
                                className={
                                    "row margin-left15 padding-top10"
                                }
                            >
                                <div className="col-3 padding-bottom30">
                                    <Label label={i18n.t(
                                        "payments.post_payments.table.claimId"
                                    )} />
                                    <AsyncTypeahead
                                        id="basic-typeahead-single"
                                        labelKey="name"
                                        minLength={0}
                                        // onChange={setPatientList}
                                        options={masterClaims}
                                        placeholder=""
                                        onSearch={onSearchInsurances}
                                        name="selectClaim"
                                        onKeyDown={(e) => selectOnTab(e)}
                                        value={selectClaim}
                                        selected={
                                            selectedClaimVals ? selectedClaimVals : ""
                                        }
                                        onChange={(e) => onHandleClaimChange(e)}
                                    />
                                </div>
                                {/* <div className="col-3 pl-0">
                                    <SelectInput
                                        data={DROP_CHARGES_DATA}
                                        selectOptionRemove={true}
                                        defaultValues={"charges"}
                                        onValueChange={(e) => onHandleChange(e)}
                                        name="selectCharge"
                                        value={selectCharge}
                                        label={i18n.t("payments.post_payments.charges")}
                                    />
                                </div> */}
                                <div
                                    style={{marginTop:30}}
                                    className={
                                        "padding-bottom20 margin-leftM7" +
                                        (autoPost ? " col-6" : " col-8")
                                    }
                                >
                                    <Link
                                        to="#"
                                        style={{ textDecoration: "none", color: "#212529" }}
                                    >
                                        <span className="paymentFormText">{i18n.t("payments.post_payments.appliedAmount")}</span>:{" "}
                                        <span className="paymentFormValueText margin-left8">{currencyFormat(sumApplied)}</span>
                                    </Link>

                                    <Link
                                        to="#"
                                        style={{ textDecoration: "none", color: "#212529" }}
                                    >
                                        <span className="paymentFormText ml-2">{i18n.t("payments.post_payments.unAppliedAmount")}</span>:{" "}
                                        <span className="paymentFormValueText margin-left8">{currencyFormat(unAppliedAmount)}</span>
                                    </Link>
                                </div>
                            </div>
                            <div
                                id="tableDiv"
                                style={{
                                    overflowX: "auto",
                                    width: "100%",
                                    maxWidth: "auto",
                                    maxHeight: "auto",
                                }}
                            >
                                {dataLoading ? (
                                    <div>
                                        <div style={loaderStyle}>
                                            <div style={spinnerStyle}></div>
                                            <span style={textStyle}>Loading data, please wait...</span>
                                        </div>
                                    </div>
                                ) : (
                                    <ResizableTable
                                        className="table-fixed_head-zindex"
                                        id="overflow_auto"
                                        tableHeadings={
                                            <tr style={{ border: '1px solid #dee2e6', zIndex: 6 }}>
                                                {tableHeadings.map((item, index) => (
                                                    <th style={{ zIndex: 6 }} key={index}>{item}</th>
                                                ))}
                                            </tr>
                                        }
                                        tableRows={
                                            <>
                                                {Object.keys(patientClaims).map((ele, index) => {
                                                    let item = patientClaims[ele];
                                                    let td_class = 'cursor-pointer';
                                                    return (
                                                        <tr key={index} className={getRowClassName(index)}>
                                                            <td align="center" className={td_class}>
                                                                <Link
                                                                    to="#"
                                                                    className="advancedSearch"
                                                                    onClick={(e) => claimRedirection(e, item)}
                                                                >
                                                                    {item.custom_claim_id}
                                                                </Link>
                                                            </td>
                                                            <td align="center" className={td_class} onClick={() => dispatch(openPatientModalWindow(item))}>
                                                                {showtableData(item?.edited ? true : false, item.totalCharges, item.totalChargeEdited)}
                                                            </td>
                                                            <td align="center" className={td_class} onClick={() => dispatch(openPatientModalWindow(item))}>
                                                                {showtableData(item?.edited ? true : false, item.totalReceiptAmount, item.totalReceiptEdited)}
                                                            </td>
                                                            <td align="center" className={td_class} onClick={() => dispatch(openPatientModalWindow(item))}>
                                                                {showtableData(item?.edited ? true : false, item.totalBalances, item.totalBalanceEdited)}
                                                            </td>
                                                            <td align="center" className={td_class} onClick={() => dispatch(openPatientModalWindow(item))}>
                                                                {showtableData(item?.edited ? true : false, item.totalAdjustments, item.totalAdjustmentsEdited)}
                                                            </td>
                                                            <td align="center" className={td_class} onClick={() => dispatch(openPatientModalWindow(item))}>
                                                                {showtableData(item?.edited ? true : false, item.totalApplyAmount, item.totalApplyAmount)}
                                                            </td>
                                                            <td align="center" className={td_class} onClick={() => dispatch(openPatientModalWindow(item))}>
                                                                {showtableData(item?.edited ? true : false, item.totalClosingBalance, item.totalClosingBalanceEdited)}
                                                            </td>
                                                            <td align="center" className={td_class}>
                                                                <div style={{ display: "flex", justifyContent: 'center' }}>
                                                                    <IconButton aria-label="edit" size="large" style={{ marginRight: 8 }} onClick={() => dispatch(openPatientModalWindow(item))}>
                                                                        <EditIcon />
                                                                    </IconButton>
                                                                    <IconButton aria-label="delete" size="large"  onClick={() => dispatch(removePatientClaimFromTable(item.custom_claim_id))}>
                                                                        <DeleteIcon />
                                                                    </IconButton>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </>
                                        }
                                    />
                                )}
                            </div>

                        </div>
                    )}
                </div>
            ) : (
                ""
            )}

            <CustomizedDialogs
                modalId={'claimDetailModal'} submit={false} modalBodyId={'claimDetailBody'} type={"save"}
                showModal={openClaimWindow} header={i18n.t("payments.eraPage.claimDetails") + `(${claimIdOpened})`}
                setShowModalWindow={() => dispatch(handleProcedureEditWindowClose())} onSaveData={() => handleProcedureEditSubmit()}
            >
                <PatientProcedureList updatePayment={props.updatePayment} />
            </CustomizedDialogs>

            <CustomizedSmallDialogs
                showModal={validationErrorModal}
                header={i18n.t("commons.alert")}
                alertOK={onAlertOk}
                type="alert"
                setShowModalWindow={() => dispatch(hideModalWindow({ key: "validationErrorModal", open: false }))}
            >
                <div className="display-grid">
                    {validationError.map((item, index) => {
                        return <span key={item.error} style={index == 0 ? {} : { marginTop: '10px' }}>{index + 1}{". "}{item.error}</span>;
                    })}
                </div>
            </CustomizedSmallDialogs>
        </>
    )
}

export default PatientClaimList;