import React from 'react';
import PropTypes from 'prop-types';
import CreditCardBox from './CreditCardBox';
import EditorIconWrapper from './EditorIconWrapper';


/**
 * TemplateFooterSection Component
 * 
 * Renders the footer section of a statement template, including payment details,
 * mailing information, and credit card payment options.
 * @component
    * @param { Object } props - Component props
    * @param { Object } props.styleProperties - Object containing style configurations
    * @param { Object } props.templateConfig - Object containing current editable state of the template
    * @param { func } props.handleEditorOpenClick - Callback to trigger when user clicks on edit icon
 * @returns { JSX.Element } Rendered footer section of the statement template
    */
function TemplateFooterSection({ styleProperties, templateConfig, handleEditorOpenClick }) {
    return (
        <>
            <div style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '5px'
            }}>
                <p style={{
                    margin: 0,
                    fontSize: '11px',
                    fontFamily: styleProperties.fonts.default,
                    color: styleProperties.colors.primaryTextColor,
                    fontWeight: 600,
                    fontStyle: 'italic',
                }}>
                    DETACH THE SECTION BELOW AND INCLUDE IN THE PAYMENT ENVELOPE
                </p>
            </div>
            <div style={{
                display: 'flex',
                width: '100%',
                gap: '15px',
                marginTop: '5px',
                border: `1px solid ${styleProperties.colors.tertiaryBoxBackground}`,
                borderRadius: '2px'
            }}>
                {/* Left Section - 60% */}
                <div style={{
                    width: '60%',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '12px'
                }}>
                    {/* Thank You Message Box */}
                    <div style={{
                        padding: '12px',
                    }}>
                        {/* Practice Name */}
                        <div style={{
                            fontSize: '16px',
                            color: styleProperties.colors.primaryTextColor,
                            fontWeight: 600,
                            marginBottom: '8px',
                        }}>
                            Thank you for choosing {templateConfig.practice_name.value || templateConfig.practice_name.default}
                        </div>

                        {/* Information Text */}
                        <EditorIconWrapper
                            onEdit={() => handleEditorOpenClick("additional_disclosure_long_text")}
                            iconSize="small"
                            iconColor="primary"
                            disabled={false}
                            editEnabled={templateConfig.editEnabled}
                        >
                        <p style={{
                            fontSize: '11px',
                            lineHeight: '1.3',
                            color: '#333',
                            marginBottom: '12px',
                            fontFamily: styleProperties.fonts.default,
                            textAlign: 'justify',
                                wordWrap: 'break-word',     // Ensures words break and wrap
                                wordBreak: 'break-word',    // Handles long words
                                whiteSpace: 'pre-wrap',     // Preserves whitespace and wraps
                                overflow: 'hidden',         // Prevents overflow
                                textOverflow: 'ellipsis',   // Shows ellipsis if text overflows
                                maxWidth: '100%',           // Ensures text stays within parent
                        }}>
                                {templateConfig.additional_disclosure_long_text.value || templateConfig.additional_disclosure_long_text.default}
                            </p>
                        </EditorIconWrapper>

                        {/* Payment Details in single row */}
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginBottom: '12px',
                            backgroundColor: styleProperties.colors.tertiaryBoxBackground,
                            padding: '8px'
                        }}>
                            {[
                                { label: 'STATEMENT DATE', value: '01/15/2024' },
                                { label: 'ACCOUNT', value: '12345678' },
                                { label: 'PAY THIS AMOUNT', value: '$50.00' },
                                { label: 'AMOUNT PAID', value: '_______________' }
                            ].map((item, index) => (
                                <div key={index} style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '2px',
                                    flex: 1,
                                    paddingRight: '8px'
                                }}>
                                    <span style={{
                                        fontSize: '10px',
                                        color: styleProperties.colors.primaryTextColor,
                                        fontWeight: 600,
                                        fontFamily: styleProperties.fonts.default,
                                        whiteSpace: 'nowrap'
                                    }}>
                                        {item.label}
                                    </span>
                                    <span style={{
                                        fontSize: '11px',
                                        color: '#000',
                                        fontFamily: styleProperties.fonts.default
                                    }}>
                                        {item.value}
                                    </span>
                                </div>
                            ))}
                        </div>

                        {/* Mailing and Check Payment Details */}
                        <div style={{
                            borderTop: `1px solid ${styleProperties.colors.tertiaryBoxBackground}`,
                            paddingTop: '10px',
                            display: 'flex',
                            gap: '40px',
                            justifyContent: 'flex-start'
                        }}>
                            {/* Mailing Address */}
                            <div style={{ flex: '0 0 auto' }}>
                                <div style={{
                                    fontSize: '12px',
                                    color: styleProperties.colors.primaryTextColor,
                                    fontWeight: 600,
                                    marginBottom: '4px',
                                    fontFamily: styleProperties.fonts.default
                                }}>
                                    Mail To:
                                </div>
                                <EditorIconWrapper
                                    onEdit={() => handleEditorOpenClick("mail_to")}
                                    iconSize="small"
                                    iconColor="primary"
                                    disabled={false}
                                    editEnabled={templateConfig.editEnabled}
                                >
                                    <div style={{
                                        fontSize: '13px',
                                        color: '#000',
                                        lineHeight: '1.4',
                                        fontFamily: styleProperties.fonts.default,
                                        fontWeight: 600,
                                        letterSpacing: '0.2px'
                                    }}>
                                        {templateConfig.mail_to?.mail_to_name?.value || templateConfig.mail_to?.mail_to_name?.default}<br />
                                        {templateConfig.mail_to?.text_1?.value || templateConfig.mail_to?.text_1?.default}<br />
                                        {(templateConfig.mail_to?.text_2?.value || templateConfig.mail_to?.text_2?.default) && (
                                            <>
                                                {templateConfig.mail_to.text_2.value || templateConfig.mail_to.text_2.default}
                                                <br />
                                            </>
                                        )}
                                        {(templateConfig.mail_to?.text_3?.value || templateConfig.mail_to?.text_3?.default) && (
                                            <>
                                                {templateConfig.mail_to.text_3.value || templateConfig.mail_to.text_3.default}
                                                <br />
                                            </>
                                        )}
                                    </div>
                                </EditorIconWrapper>
                            </div>

                            {/* Check Payable Details */}
                            <div style={{
                                flex: '0 0 auto',
                                maxWidth: '150px' 
                            }}>
                                <div style={{
                                    fontSize: '12px',
                                    color: styleProperties.colors.primaryTextColor,
                                    fontWeight: 600,
                                    marginBottom: '4px',
                                    fontFamily: styleProperties.fonts.default
                                }}>
                                    Check Payable To:
                                </div>
                                <EditorIconWrapper
                                    onEdit={() => handleEditorOpenClick("check_payable_to")}
                                    iconSize="small"
                                    iconColor="primary"
                                    disabled={false}
                                    editEnabled={templateConfig.editEnabled}
                                >
                                    <div style={{
                                        fontSize: '13px',
                                        color: '#000',
                                        lineHeight: '1.4',
                                        fontFamily: styleProperties.fonts.default,
                                        fontWeight: 600,
                                        letterSpacing: '0.2px',
                                        wordWrap: 'break-word',     // Ensures words break and wrap
                                        wordBreak: 'break-word',    // Handles long words
                                        whiteSpace: 'pre-wrap',     // Preserves whitespace and wraps
                                        overflow: 'hidden',         // Prevents overflow
                                        textOverflow: 'ellipsis',   // Shows ellipsis if text overflows
                                        maxWidth: '100%',           // Ensures text stays within parent
                                        minHeight: '2.8em',         // Accommodates two lines of text
                                        display: '-webkit-box',     // For multi-line ellipsis
                                        WebkitLineClamp: 3,         // Limits to 2 lines
                                        WebkitBoxOrient: 'vertical' // Required for line clamping
                                    }}>
                                        {templateConfig.check_payable_to.value || templateConfig.check_payable_to.default}
                                    </div>
                                </EditorIconWrapper>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Right Section - 40% */}
                <div style={{
                    width: '40%'
                }}>
                    <CreditCardBox />
                </div>
            </div>
        </>
    );
}

TemplateFooterSection.propTypes = {
    styleProperties: PropTypes.object.isRequired
};

export default TemplateFooterSection;
