import i18n from "../../../utilities/i18n";


const TableHeadData = [
    {
        "name": i18n.t("reports.visit_id"),
        "type": "number",
        "width": "extra-small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.claim_id"),
        "type": "number",
        "width": "extra-small",
        "sort": false,
        "hideValue": false,
    },
    {
        "name": i18n.t("reports.accession_number"),
        "type": "string",
        "width": "extra-small",
        "sort": false,
        "hideValue": false,
    },
    {
        "name": i18n.t("reports.patient_id"),
        "type": "string",
        "width": "extra-small",
        "sort": false,
        "hideValue": false,
    },
    {
        "name": i18n.t("reports.firstBilledDate"),
        "type": "string",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.lastBilledDate"),
        "type": "string",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.dateOfServiceFrom"),
        "type": "string",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.dateOfServiceTo"),
        "type": "string",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.firstName"),
        "type": "string",
        "width": "small",
        "sort": false,
        "hideValue": false,
    },
    {
        "name": i18n.t("reports.lastName"),
        "type": "string",
        "width": "small",
        "sort": false,
        "hideValue": false,
    },
    {
        "name": i18n.t("reports.middleName"),
        "type": "string",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.insurancePayment"),
        "type": "string",
        "width": "small",
        "sort": false,
        "hideValue": false,
    },
    {
        "name": i18n.t("reports.patientPayments"),
        "type": "string",
        "width": "small",
        "sort": false,
        "hideValue": false,
    },
    {
        "name": i18n.t("reports.totalPayments"),
        "type": "string",
        "width": "small",
        "sort": false,
        "hideValue": false,
    },
    {
        "name": i18n.t("reports.insuranceAdjustments"),
        "type": "string",
        "width": "small",
        "sort": false,
        "hideValue": false,
    },
    {
        "name": i18n.t("reports.patientWriteoff"),
        "type": "string",
        "width": "small",
        "sort": false,
        "hideValue": false,
    },
    {
        "name": i18n.t("reports.totalAdjustments"),
        "type": "string",
        "width": "small",
        "sort": false,
        "hideValue": false,
    },
    {
        "name": i18n.t("reports.insuranceBalance"),
        "type": "string",
        "width": "small",
        "sort": false,
        "hideValue": false,
    },
    {
        "name": i18n.t("reports.totalBalance"),
        "type": "string",
        "width": "small",
        "sort": false,
        "hideValue": false,
    },
    {
        "name": i18n.t("reports.refferingProvider"),
        "type": "string",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.refferingProviderNPI"),
        "type": "string",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.renderingProvider"),
        "type": "string",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.renderingProviderNPI"),
        "type": "string",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.billingProvider"),
        "type": "string",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.billingProviderNPI"),
        "type": "string",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {

        "name": i18n.t("reports.serviceLocation"),
        "type": "string",
        "width": "medium",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.cptcode"),
        "type": "string",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.units"),
        "type": "number",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.modifier1"),
        "type": "string",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.modifier2"),
        "type": "string",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.modifier3"),
        "type": "string",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.modifier4"),
        "type": "string",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.ICD_list"),
        "type": "string",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.filters.transaction_type"),
        "type": "string",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.cpt_charge_amount"),
        "type": "string",
        "width": "extra-small",
        "sort": false,
        "hideValue": false,
    },
    {
        "name": i18n.t("arClaims.payments"),
        "type": "string",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("arClaims.adjustments"),
        "type": "string",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("arClaims.patbalance"),
        "type": "string",
        "width": "small",
        "sort": false,
        "hideValue": false,
    },
    {
        "name": i18n.t("reports.procedure_balance"),
        "type": "string",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.adjustment_code"),
        "type": "string",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("patientPages.patientLedger.creditBalanceTab.transactionDate"),
        "type": "string",
        "width": "small",
        "sort": true,
        "initialOrdering": true,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.currentPayerType"),
        "type": "string",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.current_payer_name"),
        "type": "string",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.current_payer_id"),
        "type": "string",
        "width": "small",
        "sort": false,
        "hideValue": false,
    },
    {
        "name": i18n.t("reports.current_payer_policy_id"),
        "type": "string",
        "width": "small",
        "sort": false,
        "hideValue": false,
    },
    {
        "name": i18n.t("reports.current_group_no"),
        "type": "string",
        "width": "small",
        "sort": false,
        "hideValue": false,
    },
    {
        "name": i18n.t("reports.paid_payer_name"),
        "type": "string",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.paid_payer_id"),
        "type": "string",
        "width": "small",
        "sort": false,
        "hideValue": false,
    },
    {
        "name": i18n.t("reports.primary_payer_name"),
        "type": "string",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.primary_payer_id"),
        "type": "string",
        "width": "small",
        "sort": false,
        "hideValue": false,
    },
    {
        "name": i18n.t("reports.primary_policy_id"),
        "type": "string",
        "width": "small",
        "sort": false,
        "hideValue": false,
    },
    {
        "name": i18n.t("reports.primary_group_no"),
        "type": "string",
        "width": "small",
        "sort": false,
        "hideValue": false,
    },
    {
        "name": i18n.t("reports.secondary_payer_name"),
        "type": "string",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.secondary_payer_id"),
        "type": "string",
        "width": "small",
        "sort": false,
        "hideValue": false,
    },
    {
        "name": i18n.t("reports.secondary_policy_id"),
        "type": "string",
        "width": "small",
        "sort": false,
        "hideValue": false,
    },
    {
        "name": i18n.t("reports.secondary_group_no"),
        "type": "string",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.ageFromDos"),
        "type": "string",
        "width": "extra-small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.ageFromDateofEntry"),
        "type": "string",
        "width": "extra-small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.agefromfirstbillDate"),
        "type": "string",
        "width": "extra-small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.agefromlstbillDate"),
        "type": "string",
        "width": "extra-small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.claimStatus"),
        "type": "string",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.claimSubStatus"),
        "type": "string",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.entered_by"),
        "type": "string",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("payments.post_payments.insurancePayment.payment_date"),
        "type": "string",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.check_number"),
        "type": "string",
        "width": "medium",
        "sort": false,
        "hideValue": true,
    },
];

export const TableBodyData = [
    [
        {
            "id": "",
            "name": "claim_reference_number",
            "value": "",
            "type": "number",
            "needLink": true,
            "align": "center",
            "width": "extra-small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "custom_claim_id",
            "value": "",
            "type": "number",
            "align": "center",
            "width": "extra-small",
            "needLink": true,
            "hideValue": false,
        },
        {
            "id": "",
            "name": "accession_number",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "extra-small",
            "hideValue": false,
        },
        {
            "id": "",
            "name": "custom_patient_id",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "extra-small",
            "needLink": true,
            "hideValue": false,
        },
        {
            "id": "",
            "name": "first_billed_date",
            "value": "",
            "type": "string",
            "needDateFormat": "yes",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "last_billed_date",
            "value": "",
            "type": "string",
            "needDateFormat": "yes",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "service_from_date",
            "value": "",
            "type": "string",
            "needDateFormat": "yes",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "service_to_date",
            "value": "",
            "type": "string",
            "needDateFormat": "yes",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "patient_first_name",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "needLink": true,
            "hideValue": false,
        },
        {
            "id": "",
            "name": "patient_last_name",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "needLink": true,
            "hideValue": false,
        },
        {
            "id": "",
            "name": "patient_middle_name",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "needLink": true,
            "hideValue": true,
        },
        {
            "id": "",
            "name": "insurance_payment",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "patient_payments",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "total_payments",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "insurance_adjustments",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "patient_writeoff",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "total_adjustment",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "insurance_balance",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "total_balance",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "referring_provider_name",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "referring_provider_npi",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "rendering_provider_name",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "rendering_provider_npi",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "billing_provider_name",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "billing_provider_npi",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },
        {

            "id": "",
            "name": "service_location",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "medium",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "cpt_name",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "fee_units",
            "value": "",
            "type": "number",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "m1_name",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "m2_name",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "m3_name",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "m4_name",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "icds",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "transaction_type",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "cpt_charge_amount",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "extra-small",
            "hideValue": false,
        },
        {
            "id": "",
            "name": "payments",
            "value": "",
            "type": "number",
            "align": "center",
            "width": "small",
            "hideValue": false,
        },
        {
            "id": "",
            "name": "adjustments",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "hideValue": false,
        },
        {
            "id": "",
            "name": "patient_balance",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "hideValue": false,
        },
        {
            "id": "",
            "name": "procedure_balance",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "adjustment_code",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "transaction_date",
            "value": "",
            "type": "string",
            "needDateFormat": "yes",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "current_resp_payer_type",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "current_payer_name",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "current_payer_id",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "hideValue": false,
        },
        {
            "id": "",
            "name": "current_policy_id",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "hideValue": false,
        },
        {
            "id": "",
            "name": "current_group_number",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "hideValue": false,
        },
        {
            "id": "",
            "name": "paid_payer_name",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "paid_payer_id",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "hideValue": false,
        },
        {
            "id": "",
            "name": "primary_payer_name",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "primary_payer_id",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "hideValue": false,
        },
        {
            "id": "",
            "name": "primary_policy_id",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "hideValue": false,
        },
        {
            "id": "",
            "name": "primary_group_number",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "hideValue": false,
        },
        {
            "id": "",
            "name": "secondary_payer_name",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "secondary_payer_id",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "hideValue": false,
        },
        {
            "id": "",
            "name": "secondary_policy_id",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "hideValue": false,
        },
        {
            "id": "",
            "name": "secondary_group_number",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "hideValue": false,
        },
        {
            "id": "",
            "name": "age_from_dos",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "extra-small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "age_from_date_of_entry",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "extra-small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "age_from_first_billed_date",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "extra-small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "age_from_last_billed_date",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "extra-small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "claim_status",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "claim_sub_status",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "entered_by",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "payment_date",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "needLink": false,
            "hideValue": true,
        },
        {
            "id": "",
            "name": "check_number",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "medium",
            "needLink": false,
            "hideValue": true,
        },


    ]
]


export const ReportTableData = {
    "name": "dynamicTable",
    "isResize": false,
    "isReportTable": true,
    "isFixedHead": true,
    "tableHeadings": TableHeadData,
    "tableBodyData": TableBodyData
}