import React,{useState,useEffect} from 'react';
import { Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import i18n from '../../utilities/i18n';
import { Divider } from "@mui/material";
import SelectInput from '../commons/input/SelectInput';
import TextInput from '../commons/input/input';
import { SPECIAL_PROGRAM_CODES, TRANSPORT_CODE, YesOrNo } from '../../utilities/dictionaryConstants';
import CalendarInput from '../commons/input/CalendarInput';
import TextAreaInput from '../commons/textarea/textarea';
import { CityInput } from '../commons/input/CityInput';
import GeoCodeSearch from '../commons/helpers/geoCodeApiSearch';
import { convertToDecimal } from '../../utilities/commonUtilities';
import { useDispatch } from 'react-redux';
import { updateAdditionalInfoData } from './StateManagement/ClaimModuleSlice';
import moment from 'moment';

const AdditionalClaimInfo = () => {
    const dispatch = useDispatch();
    const { additionalClaimDetails, billingInfoDetails,professionalClaimDetails,claimPK } = useSelector(
        (state) => state.claimManagement
    );
    const {
        accidentTypeList,
        stateList,
        delayReasonCodeList,
        transportTypeList,
        otherAccidentNoId,
        AccidentNoId,
    } = useSelector((state) => state.claimSelectInputManager);

    let date_of_death=!claimPK ? billingInfoDetails?.date_of_death : professionalClaimDetails?.date_of_death;


  // eslint-disable-next-line
    const [returnTripValue, setReturnTripValue] = useState(false);
    
    /**
     * abulance pickup and drop of location
     */
    const [pickupCity, setPickupCity] = useState("");
    const [pickupState, setPickupState] = useState("");
    const [pickupZip, setPickupZip] = useState("");
    const [pickupCountry, setPickupCountry] = useState("");

    const [dropCity, setDropCity] = useState("");
    const [dropState, setDropState] = useState("");
    const [dropZip, setDropZip] = useState("");
    const [dropCountry, setDropCountry] = useState("");
    

    /**
     * useEffect invoke only zip code has genuine pickup details
     */
    useEffect(() => {
        if (pickupState && pickupCity && pickupCountry && pickupZip) {
            dispatch(
                updateAdditionalInfoData({
                    field: "pickup_location_data",
                    value: {
                        ...additionalClaimDetails.pickup_location_data,
                        city: pickupCity,
                        zip_code: pickupZip,
                        state: pickupState,
                        country: pickupCountry,
                    },
                    claimPK: claimPK,
                })
            );
        } else if (pickupCountry) {
            dispatch(
                updateAdditionalInfoData({
                    field: "pickup_location_data",
                    value: {
                        ...additionalClaimDetails.pickup_location_data,
                        city: pickupCity,
                        zip_code: pickupZip,
                        state: pickupState,
                        country: pickupCountry,
                    },
                    claimPK: claimPK,
                })
            );
        }
    }, [pickupZip, pickupState, pickupCountry, pickupCity]);

     /**
     * useEffect invoke only zip code has genuine drop details
     */
    useEffect(() => {
        if (dropState && dropCity && dropCountry && dropZip) {
            dispatch(
                updateAdditionalInfoData({
                    field: "dropoff_location_data",
                    value: {
                        ...additionalClaimDetails.dropoff_location_data,
                        city: dropCity,
                        zip_code: dropZip,
                        state: dropState,
                        country: dropCountry,
                    },
                    claimPK: claimPK,
                })
            );
        } else if (dropCountry) {
            dispatch(
                updateAdditionalInfoData({
                    field: "dropoff_location_data",
                    value: {
                        ...additionalClaimDetails.dropoff_location_data,
                        city: dropCity,
                        zip_code: dropZip,
                        state: dropState,
                        country: dropCountry,
                    },
                    claimPK: claimPK,
                })
            );
        }
    }, [dropZip, dropState, dropCountry, dropCity]);

     useEffect(() => {
         if (additionalClaimDetails?.ambulance_claim === 1) {
             let returnTripValues = TRANSPORT_CODE.find(
                 (item) => item.name.toLowerCase() === "return trip"
             );
             setReturnTripValue(returnTripValues.id);
         } else {
             setReturnTripValue("");
         }
     }, [additionalClaimDetails?.ambulance_claim]);
    
    /**
     * function for storing input values
     * @param {*} e 
     */

    const onHandleChange = (e) => {
        if (e.target) {
            let name = e.target.name;
            let value = e.target.value;
            if (name === "weight" || name === "distance" ) {
                value = String(value.trim())
                    .replace(/[^0-9.]/g, "") // Remove all non-numeric and non-period characters
                    .replace(/(\..*?)\./g, "$1") // Remove all extra decimal points
                    .replace(/(\.\d{2})\d*/g, "$1") // Allow only two digits after the first decimal point
                    .substring(0, 9); // Limit the length to 9 characters
            }
              
            if (e.target.type !== "checkbox") {
                if (name !== "accident") {
                    dispatch(
                        updateAdditionalInfoData({
                            field: name,
                            value: value,
                            claimPK: claimPK,
                        })
                    );
                }
                else if ((name === "accident") && (value === AccidentNoId)) {
                    dispatch(
                        updateAdditionalInfoData({
                            field: name,
                            value: value,
                            claimPK: claimPK,
                        })
                    );
                }
                else if ((name === "accident") && (value !== otherAccidentNoId)) {
                    dispatch(
                        updateAdditionalInfoData({
                            field: name,
                            value: value,
                            claimPK: claimPK,
                        })
                    );
                }
                else {
                   dispatch(
                       updateAdditionalInfoData({
                           field: name,
                           value: value,
                           claimPK: claimPK,
                       })
                   );
                }
            }
            else if (e.target.type === "checkbox") {
                let count;
                let newValue = { ...additionalClaimDetails.condition_indicator, [name]: e.target.checked };
                count = Object.values(newValue).filter((item) => item === true);
                if (count.length < 6) {
                    dispatch(
                        updateAdditionalInfoData({
                            field: "condition_indicator",
                            value: newValue,
                            claimPK: claimPK,
                        })
                    );
                }
            }
        }
    }

    /**
     * store date value to redux on calender input change or pick a new date
     * @param {*} value 
     * @param {*} name 
     */
    const onHandleDate = (value, name) => {
        try {
            let formattedDateForRedux = null;
            if (value instanceof Date && !isNaN(value)) {
                formattedDateForRedux = moment(value).format("YYYY-MM-DD");
            } else if (typeof value === "string" && moment(value, "YYYY-MM-DD", true).isValid()) {
                formattedDateForRedux = moment(value).format("YYYY-MM-DD");
            }
            dispatch(
                updateAdditionalInfoData({
                    field: name,
                    value: formattedDateForRedux,
                    claimPK: claimPK,
                })
            );
        } catch (error) {
            dispatch(
                updateAdditionalInfoData({
                    field: name,
                    value: null,
                    claimPK: claimPK,
                })
            );
        }
    };

    /**
     * function to store drop address details of ambulance
     * @param {*} e 
     */
     const onHandleChangeDropOffAdress = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        dispatch(
                updateAdditionalInfoData({
                    field: "dropoff_location_data",
                    value: {
                        ...additionalClaimDetails.dropoff_location_data,
                        [name]: value,
                    },
                    claimPK: claimPK,
                })
        );
    };

    /**
     * function to store pickup address of ambulance
     * @param {*} e 
     */
    const onHandleChangePickupAddress = (e) => {
        let name = e.target.name;
        let value = e.target.value;
       dispatch(
           updateAdditionalInfoData({
               field: "pickup_location_data",
               value: {
                   ...additionalClaimDetails.pickup_location_data,
                   [name]: value,
               },
               claimPK: claimPK,
           })
       );
    };

    /**
     * The function will call when distance is entered and click outside
     */
     const onValueBlur = () => {
        const value = additionalClaimDetails?.distance ? convertToDecimal(additionalClaimDetails?.distance, 2) : ""
         dispatch(
             updateAdditionalInfoData({
                 field: "distance",
                 value: value,
                 claimPK: claimPK,
             })
         );
    }

    return (
         <div>
            <Form autoComplete="off" className="col-md-12 margin-top20">
                <div className="row padding-left15">
                    <div className="div-inline">
                        <label className="margin-top-3px">{i18n.t("searchClaims.professionalClaims.additionalClaimInf.patientsConditionRelatedTo")}</label>
                    </div>

                </div>
                <Divider style={{ color: '#000', marginTop: 2, marginBottom: 20 }} />
                <div className="col-3 pl-0">
                        <SelectInput id="employment" data={YesOrNo} name="employment" value={additionalClaimDetails?.employment} onValueChange={(e) => onHandleChange(e)}
                        label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.employment")} selectOptionRemove={true} />
                </div>
                <div className='row'>
                    <div className="col-3 ">
                        <SelectInput data={accidentTypeList} id="accident" name="accident" value={additionalClaimDetails?.accident}
                            onValueChange={(e) => onHandleChange(e)} label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.accident")} selectOptionRemove={true} />
                    </div>
                    {(AccidentNoId == additionalClaimDetails?.accident) ? "" :
                        <div className="col-3 pl-0">
                            <div className="form-group">
                                <SelectInput name="state" data={stateList}
                                    id="state"
                                    value={additionalClaimDetails?.state} onValueChange={(e) => onHandleChange(e)} required={true}
                                    label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.Statement")} />
                            </div>
                        </div>
                    }
                    {additionalClaimDetails?.accident=== otherAccidentNoId ?
                        <div className="col-3 pl-0">
                            <TextInput type="text" id="other_accident" name="other_accident" value={additionalClaimDetails?.other_accident} onValueChange={(e) => onHandleChange(e)} label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.otherAccident")} />
                        </div>
                        : ""}
                </div>
                <div className="row">
                    {(additionalClaimDetails?.accident) !== 1 &&
                        <div className="col-3 ">
                            <CalendarInput name="accident_date" id="accident_date" label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.accidentDate")}
                                selected={additionalClaimDetails?.accident_date} onValueChange={(e) => onHandleDate(e, "accident_date")}
                                required={additionalClaimDetails?.accident && additionalClaimDetails?.accident != AccidentNoId ? true : false}
                                maxDate={date_of_death ?? new Date()}  minDate={professionalClaimDetails?.patient_dob}
                            />
                        </div>
                    }
                    {/* In the below conditionally passing the column width to properly align the input controller, for some reason the below controllers...
                        gets mis-aligned when conditional rendering happens on the accident details, last_menstural_date & initial_treatment_date are getting pushed towards left when the className not handled this way  */}
                    {billingInfoDetails?.gender_name == "Female"?
                    <div className={(additionalClaimDetails?.accident) !== 1 ? "col-3 pl-0" : "col-3" }>
                        <CalendarInput id="last_menstural_date" name="last_menstural_date" label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.lastMenstrualDate")}
                            selected={additionalClaimDetails?.last_menstural_date} onValueChange={(e) => onHandleDate(e, "last_menstural_date")}
                            maxDate={date_of_death ?? new Date()}  minDate={professionalClaimDetails?.patient_dob}
                        />
                    </div> :null}  
                    <div className={(additionalClaimDetails?.accident) !== 1 ? "col-3 pl-0" : "col-3" }>
                            <CalendarInput name="initial_treatment_date" id="initial_treatment_date" label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.initialTreatmentDate")}
                            selected={additionalClaimDetails?.initial_treatment_date} onValueChange={(e) => onHandleDate(e, "initial_treatment_date")}
                            maxDate={date_of_death ?? new Date()} minDate={professionalClaimDetails?.patient_dob}
                        />
                    </div>
                    <div className="col-3 pl-0">
                        <CalendarInput name="last_seen_date" id="last_seen_date" label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.lastSeenDate")}
                            selected={additionalClaimDetails?.last_seen_date} onValueChange={(e) => onHandleDate(e, "last_seen_date")}
                            maxDate={date_of_death ?? new Date()}  minDate={professionalClaimDetails?.patient_dob}
                        />
                    </div>
                </div>
                <div className="row">

                    <div className="col-3">
                        <CalendarInput name="unable_work_from_date" id="unable_work_from_date" label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.unableToWorkFrom")}
                            selected={additionalClaimDetails?.unable_work_from_date} onValueChange={(e) => onHandleDate(e, "unable_work_from_date")}
                            maxDate={date_of_death ?? new Date()}  minDate={professionalClaimDetails?.patient_dob}
                        />
                    </div>
                    <div className="col-3 pl-0">
                        <CalendarInput name="unable_work_to_date" id="unable_work_to_date" label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.unableToWorkTo")}
                            selected={additionalClaimDetails?.unable_work_to_date} onValueChange={(e) => onHandleDate(e, "unable_work_to_date")}
                            maxDate={date_of_death ?? new Date()} minDate={additionalClaimDetails?.unable_work_from_date ?? professionalClaimDetails?.patient_dob}
                        />
                    </div>
                    <div className="col-3 pl-0">
                        <CalendarInput name="hospitalization_from_date" id="hospitalization_from_date" label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.hospitalizeFromDate")}
                            selected={additionalClaimDetails?.hospitalization_from_date} onValueChange={(e) => onHandleDate(e, "hospitalization_from_date")}
                            maxDate={date_of_death ?? new Date()} minDate={professionalClaimDetails?.patient_dob}
                        />
                    </div>
                    <div className="col-3 pl-0">
                        <CalendarInput name="hospitalization_to_date" id="hospitalization_to_date" label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.hospitalizeToDate")}
                            selected={additionalClaimDetails?.hospitalization_to_date} onValueChange={(e) => onHandleDate(e, "hospitalization_to_date")}
                            maxDate={date_of_death ?? new Date()} minDate={additionalClaimDetails?.hospitalization_from_date ?? professionalClaimDetails?.patient_dob}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-3">
                        <CalendarInput name="assumed_care_date" id="assumed_care_date" label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.assumedCareDate")}
                            selected={additionalClaimDetails?.assumed_care_date} onValueChange={(e) => onHandleDate(e, "assumed_care_date")}
                            maxDate={date_of_death ?? new Date()} minDate={professionalClaimDetails?.patient_dob}
                        />
                    </div>
                    <div className="col-3 pl-0">
                        <CalendarInput name="disability_begin_date" label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.disabilityBeginDate")}
                            selected={additionalClaimDetails?.disability_begin_date} onValueChange={(e) => onHandleDate(e, "disability_begin_date")}
                            maxDate={date_of_death ?? new Date()} minDate={professionalClaimDetails?.patient_dob}
                        />
                    </div>
                    <div className="col-3 pl-0">
                        <CalendarInput name="disability_end_date" id="disability_end_date" label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.disabilityEndDate")}
                            selected={additionalClaimDetails?.disability_end_date} onValueChange={(e) => onHandleDate(e, "disability_end_date")}
                            maxDate={date_of_death ?? new Date()} minDate={additionalClaimDetails?.disability_begin_date ?? professionalClaimDetails?.patient_dob}
                        />
                    </div>
                    <div className="col-3 pl-0">
                        <CalendarInput name="relinquished_date" id="relinquished_date" label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.relinquishedDate")}
                            selected={additionalClaimDetails?.relinquished_date} onValueChange={(e) => onHandleDate(e, "relinquished_date")}
                            maxDate={date_of_death ?? new Date()} minDate={professionalClaimDetails?.patient_dob}
                        />
                    </div>
                </div>
                <div className="row padding-left15">
                    <div className="div-inline">
                        <label className="margin-top-3px">
                            {i18n.t("searchClaims.professionalClaims.additionalClaimInf.additionalInfo")}
                        </label>
                    </div>

                </div>
                <Divider style={{ color: '#000', marginTop: 2, marginBottom: 20 }} />
                <div className="row">
                    <div className="col-3">
                        <div className="form-group">
                            <TextInput type="text" id="mammography_cert_number" name="mammography_cert_number" value={additionalClaimDetails?.mammography_cert_number} onValueChange={(e) => onHandleChange(e)} label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.mamograpghyCertNumber")} />
                        </div>
                    </div>
                    <div className="col-3 pl-0">
                        <div className="form-group">
                            <TextInput type="text" id="care_plan_number" name="care_plan_number" value={additionalClaimDetails?.care_plan_number} onValueChange={(e) => onHandleChange(e)} label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.carePlanNumber")} />
                        </div>
                    </div>
                    <div className="col-3 pl-0">
                        <div className="form-group">
                            <TextInput type="text" id="service_authorization_code" name="service_authorization_code" value={additionalClaimDetails?.service_authorization_code} onValueChange={(e) => onHandleChange(e)} label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.serviceAuthCode")} />
                        </div>
                    </div>
                    <div className="col-3 pl-0">
                        <div className="form-group">
                            <TextInput type="text" id="clia_number" name="clia_number" value={additionalClaimDetails?.clia_number ? additionalClaimDetails?.clia_number : ''} onValueChange={(e) => onHandleChange(e)} label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.cliaNumber")} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-3">
                        <div className="form-group">
                            <TextInput type="text" id="ide_number" name="ide_number" value={additionalClaimDetails?.ide_number} onValueChange={(e) => onHandleChange(e)} label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.ideNumber")} />
                        </div>
                    </div>
                    <div className="col-3 pl-0">
                        <SelectInput data={SPECIAL_PROGRAM_CODES} id="special_program_code" name="special_program_code" value={additionalClaimDetails?.special_program_code} onValueChange={(e) => onHandleChange(e)} label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.specialProgramCode")} />
                    </div>
                    <div className="col-3 pl-0">
                        <div className="form-group padding-top">
                            <TextInput name="project_demonstration_id" id="project_demonstration_id" value={additionalClaimDetails?.project_demonstration_id} onValueChange={(e) => onHandleChange(e)}
                                label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.projectDemonstrationId")} />
                        </div>
                    </div>
                    <div className="col-3 pl-0">
                        <SelectInput data={YesOrNo} id="patient_homebound" name="patient_homebound" value={additionalClaimDetails?.patient_homebound} onValueChange={(e) => onHandleChange(e)} label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.patientHomebound")} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-9">
                        <SelectInput data={delayReasonCodeList} id="claim_delay_reason" name="claim_delay_reason" value={additionalClaimDetails?.claim_delay_reason} onValueChange={(e) => onHandleChange(e)} label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.claimDelay")} />
                    </div>
                </div>
                <div className="justify-normal">
                    <div className="div-inline">
                        <label className="margin-top-3px">{i18n.t("searchClaims.professionalClaims.additionalClaimInf.claimNote")}</label>
                    </div>
                </div>
                <div className="row">
                    <div className="col-9">
                        <div className="form-group">
                            <TextAreaInput className="" id="claim_note"  name="claim_note" value={additionalClaimDetails?.claim_note} onValueChange={(e) => onHandleChange(e)}
                                placeholder={i18n.t("searchClaims.professionalClaims.additionalClaimInf.claimNote")} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-3">
                        <div className="form-group padding-top">
                            <TextInput type="text" name="lab_sales_rep" id="lab_sales_rep" value={additionalClaimDetails?.lab_sales_rep} onValueChange={(e) => onHandleChange(e)} label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.labSalesRep")} />
                        </div>
                    </div>
                    <div className="col-3 pl-0">
                        <div className="form-group padding-top">
                            <TextInput type="text" name="clinic_site_name" id="clinic_site_name" value={additionalClaimDetails?.clinic_site_name} onValueChange={(e) => onHandleChange(e)} label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.clinicName")} />
                        </div>
                    </div>
                    <div className="col-3 pl-0">
                        <div className="form-group padding-top">
                            <TextInput type="text" name="accession_number" id="accession_number" value={additionalClaimDetails?.accession_number} onValueChange={(e) => onHandleChange(e)} label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.accession")} />
                        </div>
                    </div>
                    <div className="col-3 pl-0">
                        <div className="form-group padding-top">
                            <TextInput type="text" name="claim_service_type" id="claim_service_type" value={additionalClaimDetails?.claim_service_type} onValueChange={(e) => onHandleChange(e)} label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.claimServiceType")} />
                        </div>
                    </div>
                </div>
                   <div className="row padding-left15">
                    <div className="div-inline">
                        <label className="margin-top-3px">
                            {i18n.t("searchClaims.professionalClaims.additionalClaimInf.ambulanceInfo")}
                        </label>
                    </div>

                </div>
                <Divider style={{ color: '#000', marginTop: 2, marginBottom: 20 }} />
                <div className="row">
                    <div className="col-3">
                        <SelectInput data={YesOrNo} id="ambulance_claim" name="ambulance_claim" value={additionalClaimDetails?.ambulance_claim} onValueChange={(e) => onHandleChange(e)} selectOptionRemove={true} label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.ambulanceClaim")} />
                    </div>
                </div>
                  {additionalClaimDetails?.ambulance_claim !== 2 &&
                    <>
                    <div className="row">
                        <div className="col-3">
                                <div className="form-group padding-top">
                                    <TextInput type="text" id="weight" name="weight" required={true} value={additionalClaimDetails?.weight} onValueChange={(e) => onHandleChange(e)} label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.weight")} />
                                </div>
                            </div>
                        <div className="col-3 pl-0">
                                <SelectInput data={TRANSPORT_CODE} id="transport_code" name="transport_code" required={true} value={additionalClaimDetails?.transport_code} onValueChange={(e) => onHandleChange(e)} label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.transportCode")} />
                            </div>
                        <div className="col-3 pl-0">
                                <SelectInput data={transportTypeList} id="transport_reason" name="transport_reason" required={true} value={additionalClaimDetails?.transport_reason} onValueChange={(e) => onHandleChange(e)} label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.transportReason")} />
                            </div>
                        <div className="col-3 pl-0">
                                <div className="form-group padding-top">
                                    <TextInput type="text" id="distance" name="distance" required={true} onValueBlur={() => onValueBlur()} value={additionalClaimDetails?.distance} onValueChange={(e) => onHandleChange(e)} label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.distance")} />
                                </div>
                            </div>
                        </div>
                        {(returnTripValue === Number(additionalClaimDetails?.transport_code)) &&
                            <div className="justify-normal">
                                <div className="input-content-box  input-responsible-cliams">
                                    <div className="form-group padding-top">
                                        <TextInput type="text" name="return_trip_reason" id="return_trip_reason" required={true} value={additionalClaimDetails?.return_trip_reason} onValueChange={(e) => onHandleChange(e)} label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.returnTripReason")} />
                                    </div>
                                </div>
                            </div>
                        }
                    <div className="row">
                        <div className="col-3">
                                <div className="form-group padding-top">
                                <TextInput type="text" name="stretcher_reason" id="stretcher_reason" required={true} value={additionalClaimDetails?.stretcher_reason} onValueChange={(e) => onHandleChange(e)} label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.stretcherReason")} />
                            </div>
                        </div>
                        <div className="col-3 pl-0">
                            <SelectInput data={YesOrNo} name="certification" id="certification" required={true} value={additionalClaimDetails?.certification} onValueChange={(e) => onHandleChange(e)}
                                label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.certification")} />
                        </div>
                        </div>
                    <h6 className="padding-top10 padding-left3">{i18n.t("searchClaims.professionalClaims.additionalClaimInf.conditionIndicator")}</h6>

                    <div className="row">
                            <div className="input-content-box">
                                <div className="row pb-0">
                                    <div className="col-1 max-width-3">
                                        <div className="form-group">
                                            <div className="custom-checkbox lh-0">
                                                <input type="checkbox" id="patient_admitted_hospital" name="patient_admitted_hospital"
                                                    checked={additionalClaimDetails?.condition_indicator?.patient_admitted_hospital} onChange={(e) => onHandleChange(e)}
                                                />
                                                <label className="checkbox" htmlFor="patient_admitted_hospital">{i18n.t("searchClaims.professionalClaims.additionalClaimInf.sameAsPractice")}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col padding-top5">
                                        <label className="padding-top5 margin-top-3px">{i18n.t("searchClaims.professionalClaims.additionalClaimInf.patientAdmittedToHsptl")}</label>
                                    </div>
                                </div>
                            </div>
                            <div className="input-content-box pr-0">
                                <div className="justify-normal pb-0">
                                    <div className="col-1 max-width-3">
                                        <div className="form-group">
                                            <div className="custom-checkbox lh-0">
                                                <input type="checkbox" id="patient_moved_by_stretcher" name="patient_moved_by_stretcher"
                                                    checked={additionalClaimDetails?.condition_indicator?.patient_moved_by_stretcher} onChange={(e) => onHandleChange(e)}
                                                />
                                                <label className="checkbox" htmlFor="patient_moved_by_stretcher">{i18n.t("searchClaims.professionalClaims.additionalClaimInf.sameAsPractice")}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col padding-top5">
                                        <label className="padding-top5 margin-top-3px">{i18n.t("searchClaims.professionalClaims.additionalClaimInf.patientMovedStretcher")}</label>
                                    </div>

                                </div>
                            </div>
                            <div className="">
                                <div className="justify-normal pb-0">
                                    <div className="col-1 max-width-3">
                                        <div className="form-group">
                                            <div className="custom-checkbox lh-0">
                                                <input type="checkbox" id="patient_unconscious_shock" name="patient_unconscious_shock"
                                                    checked={additionalClaimDetails?.condition_indicator?.patient_unconscious_shock} onChange={(e) => onHandleChange(e)}
                                                />
                                                <label className="checkbox" htmlFor="patient_unconscious_shock">{i18n.t("searchClaims.professionalClaims.additionalClaimInf.sameAsPractice")}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col padding-top5">
                                        <label className="padding-top5 margin-top-3px">{i18n.t("searchClaims.professionalClaims.additionalClaimInf.patientUnconscious")}</label>
                                    </div>

                                </div>
                            </div>
                        </div>


                    <div className="row">
                            <div className="input-content-box">
                                <div className="row pb-0">
                                    <div className="col-1 max-width-3">
                                        <div className="form-group">
                                            <div className="custom-checkbox lh-0">
                                                <input type="checkbox" id="patient_transported_emergency" name="patient_transported_emergency"
                                                    checked={additionalClaimDetails?.condition_indicator?.patient_transported_emergency} onChange={(e) => onHandleChange(e)}
                                                />
                                                <label className="checkbox" htmlFor="patient_transported_emergency">{i18n.t("searchClaims.professionalClaims.additionalClaimInf.sameAsPractice")}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col padding-top5">
                                        <label className="padding-top5 margin-top-3px">{i18n.t("searchClaims.professionalClaims.additionalClaimInf.patientTransportInEmergency")}</label>
                                    </div>
                                </div>
                            </div>
                            <div className="input-content-box pr-0">
                                <div className="justify-normal pb-0">
                                    <div className="col-1 max-width-3">
                                        <div className="form-group">
                                            <div className="custom-checkbox lh-0">
                                                <input type="checkbox" id="patient_physically_restrained" name="patient_physically_restrained"
                                                    checked={additionalClaimDetails?.condition_indicator?.patient_physically_restrained} onChange={(e) => onHandleChange(e)}
                                                />
                                                <label className="checkbox" htmlFor="patient_physically_restrained">{i18n.t("searchClaims.professionalClaims.additionalClaimInf.sameAsPractice")}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col padding-top5">
                                        <label className="padding-top5 margin-top-3px">{i18n.t("searchClaims.professionalClaims.additionalClaimInf.patientPhysicallyRestrained")}</label>
                                    </div>

                            </div>
                        </div>
                        <div className="">
                            <div className="justify-normal pb-0">
                                <div className="col-1 max-width-3">
                                    <div className="form-group">
                                        <div className="custom-checkbox lh-0">
                                            <input type="checkbox" id="patient_confined_bed_chair" name="patient_confined_bed_chair"
                                                checked={additionalClaimDetails?.condition_indicator?.patient_confined_bed_chair} onChange={(e) => onHandleChange(e)}
                                            />
                                            <label className="checkbox" htmlFor="patient_confined_bed_chair">{i18n.t("searchClaims.professionalClaims.additionalClaimInf.sameAsPractice")}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col padding-top5">
                                    <label className="padding-top5 margin-top-3px">{i18n.t("searchClaims.professionalClaims.additionalClaimInf.patietConfinedToBed")}</label>
                                </div>

                            </div>
                        </div>
                        </div>

                    <div className="row">
                            <div className="input-content-box">
                                <div className="row pb-0">
                                    <div className="col-1 max-width-3">
                                        <div className="form-group">
                                            <div className="custom-checkbox lh-0">
                                                <input type="checkbox" id="patient_visible_haemorrhaging" name="patient_visible_haemorrhaging"
                                                    checked={additionalClaimDetails?.condition_indicator?.patient_visible_haemorrhaging} onChange={(e) => onHandleChange(e)}
                                                />
                                                <label className="checkbox" htmlFor="patient_visible_haemorrhaging">{i18n.t("searchClaims.professionalClaims.additionalClaimInf.sameAsPractice")}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col padding-top5">
                                        <label className="padding-top5 margin-top-3px">{i18n.t("searchClaims.professionalClaims.additionalClaimInf.patientVisibleHaemorhag")}</label>
                                    </div>
                                </div>
                            </div>
                            <div className="input-content-box">
                                <div className="justify-normal pb-0">
                                    <div className="col-1 max-width-3">
                                        <div className="form-group">
                                            <div className="custom-checkbox lh-0">
                                                <input type="checkbox" id="ambulance_service_medical_necessary" name="ambulance_service_medical_necessary"
                                                    checked={additionalClaimDetails?.condition_indicator?.ambulance_service_medical_necessary} onChange={(e) => onHandleChange(e)}
                                                />
                                                <label className="checkbox" htmlFor="ambulance_service_medical_necessary">{i18n.t("searchClaims.professionalClaims.additionalClaimInf.sameAsPractice")}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col padding-top5">
                                    <label className="padding-top5 margin-top-3px">{i18n.t("searchClaims.professionalClaims.additionalClaimInf.ambulanceMedicalNecessary")}</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row padding-top20 padding-left15">
                            <div className="div-inline">
                                <label className="margin-top-3px">{i18n.t("searchClaims.professionalClaims.additionalClaimInf.pickupAddress")}</label>
                            </div>

                    </div>
                    <Divider style={{ color: '#000', marginTop: 2, marginBottom: 20 }} />
                        <div className="row padding-left3">
                            <div className="col-3">
                                <div className="form-group">
                                    <TextInput type="text" name="street1" id="pickup-street1" required={true} value={additionalClaimDetails?.pickup_location_data?.street1} onValueChange={(e) => onHandleChangePickupAddress(e)} label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.pickupStreet1")} />
                                </div>
                            </div>
                        <div className="col-3 pl-0">
                                <div className="form-group">
                                    <TextInput type="text" name="street2" id="pickup-street2" value={additionalClaimDetails?.pickup_location_data?.street2} onValueChange={(e) => onHandleChangePickupAddress(e)} label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.pickupStreet2")} />
                                </div>
                            </div>
                        </div>
                        <div className="row padding-left3">
                            <div className="col-3">
                                <div className="form-group">
                                    <CityInput type="text" id="pickup_location_data_city" name="city" required={true} value={additionalClaimDetails?.pickup_location_data?.city} onValueChange={(e) => onHandleChangePickupAddress(e)} label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.pickupCity")}
                                        zip={additionalClaimDetails?.pickup_location_data?.zip_code} />
                                </div>
                            </div>
                        <div className="col-3 pl-0">
                                <div className="form-group">
                                    <TextInput type="text" name="state" id="pickup-state" required={true} value={additionalClaimDetails?.pickup_location_data?.state} onValueChange={(e) => onHandleChangePickupAddress(e)} label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.pickupState")} />
                                </div>
                            </div>
                        <div className="col-3 pl-0">
                                <div className="form-group">
                                    <GeoCodeSearch id="zip_code" name="zip_code" required={true}
                                        label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.pickupZip")}
                                        setlocState={setPickupState} setLocCountry={setPickupCountry} setLocZipCode={setPickupZip}
                                        setLocCity={setPickupCity} defaultValue={additionalClaimDetails?.pickup_location_data?.zip_code}
                                        onValueChange={(e) => onHandleChangePickupAddress(e)} 
                                    />
                                </div>
                            </div>
                        <div className="col-3 pl-0">
                                <div className="form-group">
                                    <TextInput type="text" name="country" id="pickup-country" value={additionalClaimDetails?.pickup_location_data?.country} onValueChange={(e) => onHandleChangePickupAddress(e)} label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.pickupCountry")} />
                                </div>
                            </div>
                        </div>

                        <div className="row padding-left15">
                            <div className="div-inline">
                                <label className="margin-top-3px">{i18n.t("searchClaims.professionalClaims.additionalClaimInf.drop-offAddress")}</label>
                            </div>
                    </div>
                    <Divider style={{ color: '#000', marginTop: 2, marginBottom: 20 }} />
                        <div className="row padding-left3">
                            <div className="col-3">
                                <div className="form-group">
                                    <TextInput type="text" name="street1" id="dropoff-street1" required={true} value={additionalClaimDetails?.dropoff_location_data?.street1} onValueChange={(e) => onHandleChangeDropOffAdress(e)} label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.dropStreet1")} />
                                </div>
                            </div>
                        <div className="col-3 pl-0">
                                <div className="form-group">
                                    <TextInput type="text" name="street2" id="dropoff-street2" value={additionalClaimDetails?.dropoff_location_data?.street2} onValueChange={(e) => onHandleChangeDropOffAdress(e)} label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.dropStreet2")} />
                                </div>
                            </div>
                        </div>
                        <div className="row  padding-left3">
                            <div className="col-3">
                                <div className="form-group">
                                    <CityInput type="text" id='dropoff_location_data_city' name="city" required={true} value={additionalClaimDetails?.dropoff_location_data?.city} onValueChange={(e) => onHandleChangeDropOffAdress(e)} label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.dropCity")}
                                        zip={additionalClaimDetails?.dropoff_location_data?.zip_code} />
                                </div>
                            </div>
                        <div className="col-3 pl-0">
                                <div className="form-group">
                                    <TextInput type="text" name="state" id="dropoff_state" required={true} value={additionalClaimDetails?.dropoff_location_data?.state} onValueChange={(e) => onHandleChangeDropOffAdress(e)} label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.dropState")} />
                                </div>
                            </div>
                        <div className="col-3 pl-0">
                                <div className="form-group">
                                    <GeoCodeSearch id="zip_code" name="zip_code" required={true}
                                        label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.dropZip")}
                                        setlocState={setDropState} setLocCountry={setDropCountry} setLocZipCode={setDropZip}
                                        setLocCity={setDropCity} onValueChange={(e) => onHandleChangeDropOffAdress(e)} 
                                        defaultValue={additionalClaimDetails?.dropoff_location_data?.zip_code}
                                    />
                                </div>
                            </div>
                        <div className="col-3 pl-0">
                                <div className="form-group">
                                    <TextInput type="text" name="country" id="dropoff-country" value={additionalClaimDetails?.dropoff_location_data?.country} onValueChange={(e) => onHandleChangeDropOffAdress(e)} label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.dropCountry")} />
                                </div>
                            </div>
                        </div>
                    </>
                }
                <div className="row padding-top20 padding-left15">
                    <div className="div-inline">
                        <label className="margin-top-3px">{i18n.t("searchClaims.professionalClaims.additionalClaimInf.labNote")}</label>
                    </div>
                </div>
                <Divider style={{ color: '#000', marginTop: 2, marginBottom: 20 }} />
                <div className="row">
                    <div className='col-9'>
                    <div className="form-group">
                        <TextAreaInput className="" id="lab_notes" name="lab_notes" value={additionalClaimDetails?.lab_notes} onValueChange={(e) => onHandleChange(e)}
                            placeholder={i18n.t("searchClaims.professionalClaims.additionalClaimInf.labNote")} />
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    )
    
    
}

export default AdditionalClaimInfo;