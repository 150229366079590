
import React, { useMemo, useState } from 'react'
import Popover from '@mui/material/Popover';
import { Checkbox, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import settings from "../../../assets/images/setting13.svg"


/**
 * 
 * @param {options} Array list of options
 * @param {selected} Array list of selected items within the options
 * @param {MaterialMultiselectHandleChange} func Handle change function to select and deselect
 * @returns 
 */
function TableHeaderSelector({
    selected,
    options,
    MaterialMultiselectHandleChange
}) {
    
    const [popOverAnchor, SetPopOverAnchor] = useState(null);
    const [isPopOverOpen, SetIsPopOverOpen] = useState(false);
    const handleClickPopOver = (event) => {
        SetPopOverAnchor(event.currentTarget);
        SetIsPopOverOpen(true)
    };
    const handlePopOverClose = () => {
        SetPopOverAnchor(null);
        SetIsPopOverOpen(false)

    };

    const handleSelectAll = (e) => {
        const { checked } = e.target;
        if (checked) {
          // Select all options
          MaterialMultiselectHandleChange(options);
        } else {
          // Deselect all options
          MaterialMultiselectHandleChange([]);
        }
    }

    const filteredSelected = useMemo(() => {
        return selected.filter((item) => options.includes(item))
    }, [selected])

    const isAllSelected =  useMemo(() => {
        return filteredSelected?.length === options?.length;
    }, [filteredSelected])

    return (
        <>
            <div style={{ marginBottom: '20px', paddingLeft: '10px' }}>
                <img src={settings} style={{ height: '36px', width: '36px', cursor: 'pointer' }} alt="" onClick={handleClickPopOver} />
                <div className='row'>
                    <div >
                        <Popover
                            id={1}
                            open={isPopOverOpen}
                            anchorEl={popOverAnchor}
                            onClose={handlePopOverClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >

                            {options.length > 0 && (
                                <>
                                    {/* Select All Checkbox */}
                                    <Stack
                                        sx={{
                                            borderRadius: '3px',
                                            cursor: 'pointer',
                                            background: selected.length === options.length ? 'rgba(158,207,250,0.3)' : '',
                                            '&:hover': {
                                                backgroundColor: selected.length !== options.length ? '#dbdfe2a8' : ''
                                            },
                                        }}
                                        direction="row"
                                        alignItems="center"
                                        gap={1}
                                        padding="5px"
                                        onClick={() => handleSelectAll({ target: { checked: !isAllSelected } })}
                                    >
                                        <Checkbox
                                            checked={isAllSelected}
                                            onChange={(e)=>handleSelectAll(e)}
                                            style={{
                                                color: isAllSelected ? "#404040" : "inherit",
                                            }} 
                                        />
                                        <Typography variant='subtitle1'>
                                            <strong>Select All</strong>
                                        </Typography>
                                    </Stack>

                                    {/* Options List */}

                                    {options.map((item) => {
                                        
                                        const isChecked = selected.includes(item);
                                        return (
                                            <Stack
                                                key={item}
                                                sx={{
                                                    borderRadius: '3px',
                                                    cursor: 'pointer',
                                                    background: isChecked ? 'rgba(158,207,250,0.3)' : '',
                                                    '&:hover': {
                                                        backgroundColor: !isChecked ? '#dbdfe2a8' : ''
                                                    },
                                                }}
                                                onClick={() => MaterialMultiselectHandleChange(item)}
                                                direction="row"
                                                alignItems="center"
                                                gap={1}
                                                padding="5px"
                                            >
                                                <Checkbox
                                                    checked={isChecked}
                                                    style={{ color: isChecked ? '#404040' : 'inherit' }}
                                                />
                                                <Typography variant='subtitle1'>
                                                    {item}
                                                </Typography>
                                            </Stack>
                                        );
                                    })}

                                    
                                </>
                            )}
                        </Popover>
                    </div>
                </div>

                <div>
                </div>
            </div>

        </>
    )
}



export default TableHeaderSelector;