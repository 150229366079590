import i18n from "../../../../utilities/i18n";

export const colPatientName = i18n.t("patientPages.patients.labelPatientName");
export const colSelPID = i18n.t("patientPages.patients.labelPatientID");
export const colSelGENDER = i18n.t("patientPages.patients.labelGender");
export const colSelDOB = i18n.t("patientPages.patients.labelDOB");
export const colSelPTYPE = i18n.t("patientPages.patients.labelPatientType");
export const colSelADDRESS = i18n.t("patientPages.patients.labelPatientAddress");
export const colSelHOMEPHONE = i18n.t("patientPages.patients.labelPatientHomePhone");
export const colSelGUARANTOR = i18n.t("patientPages.patients.labelGuarantor");
export const colSelGUARANTORRELATIONSHIP = i18n.t("patientPages.patients.labelGuarantorRelationship");
export const colSelGUARANTORADDRESS = i18n.t("patientPages.patients.labelGuarantorAddress");
export const colSelGUARANTORHOMEPHONE = i18n.t("patientPages.patients.labelGuarantorHomePhone");
export const colSelEMERGENCY = i18n.t("patientPages.patients.labelEmergency");
export const colSelEMERGENCYRELATIONSHIP = i18n.t("patientPages.patients.labelEmergencyRelationship");
export const colSelEMERGENCYADDRESS = i18n.t("patientPages.patients.labelEmergencyAddress");
export const colSelEMERGENCYHOMEPHONE = i18n.t("patientPages.patients.labelEmergencyHomePhone");
export const colSelPINSURANCENAME = i18n.t("patientPages.patients.labelPrimaryInsuranceName");
export const colSelPINSURANCEPOLICYID = i18n.t("patientPages.patients.labelPrimaryInsurancePolicyID");
export const colSelPEFFECTIVEDATE = i18n.t("patientPages.patients.labelPrimaryEffectiveDate");
export const colSelPSUBRELATIONSHIP = i18n.t("patientPages.patients.labelPSubscriberRelationship");
export const colSelPSUBNAME = i18n.t("patientPages.patients.labelPSubscriberName");
export const colSelPSUBGENDER = i18n.t("patientPages.patients.labelPSubscriberGender");
export const colSelPSUBDOB = i18n.t("patientPages.patients.labelPSubscriberDOB");
export const colSelPSUBADDRESS = i18n.t("patientPages.patients.labelPSubscriberAddress");
export const colSelACTION = i18n.t("patientPages.patients.labelAction");

// add non selectable field to this const to get queryfields while API call
export const patienfFieldMandatory = [colPatientName];
export const patienfFieldArray = [
    // add all fields to this const
    colPatientName,
    colSelPID,
    colSelGENDER,
    colSelDOB,
    colSelPTYPE,
    colSelADDRESS,
    colSelHOMEPHONE,
    colSelGUARANTOR,
    colSelGUARANTORRELATIONSHIP,
    colSelGUARANTORADDRESS,
    colSelGUARANTORHOMEPHONE,
    colSelEMERGENCY,
    colSelEMERGENCYRELATIONSHIP,
    colSelEMERGENCYADDRESS,
    colSelEMERGENCYHOMEPHONE,
    colSelPINSURANCENAME,
    colSelPINSURANCEPOLICYID,
    colSelPEFFECTIVEDATE,
    colSelPSUBRELATIONSHIP,
    colSelPSUBNAME,
    colSelPSUBGENDER,
    colSelPSUBDOB,
    colSelPSUBADDRESS,
    colSelACTION,
];


export const PatientTableHead = [
    // selectable fields only should be mentioned here
    colPatientName,
    colSelPID,
    colSelGENDER,
    colSelDOB,
    colSelPTYPE,
    colSelADDRESS,
    colSelHOMEPHONE,
    colSelGUARANTOR,
    colSelGUARANTORRELATIONSHIP,
    colSelGUARANTORADDRESS,
    colSelGUARANTORHOMEPHONE,
    colSelEMERGENCY,
    colSelEMERGENCYRELATIONSHIP,
    colSelEMERGENCYADDRESS,
    colSelEMERGENCYHOMEPHONE,
    colSelPINSURANCENAME,
    colSelPINSURANCEPOLICYID,
    colSelPEFFECTIVEDATE,
    colSelPSUBRELATIONSHIP,
    colSelPSUBNAME,
    colSelPSUBGENDER,
    colSelPSUBDOB,
    colSelPSUBADDRESS,
    colSelACTION
];

export const defaultSelectedColumnsList = [
    "full_name", //default selected 1
    "patient_gender", //default selected 2
    "dob", //default selected 3
    "practice_patient_type", //default selected 4
    "street1", //default selected 5
    "street2", //default selected 6
    "city", //default selected 7
    "state", //default selected 8
    "patient_zip", //default selected 9
    "home_phone", //default selected 10
    "p_insurance_name", //default selected 11
    "p_insurance_policy_id", //default selected 12
    "s_insurance_name", //default selected 13
    "s_insurance_policy_id", //default selected 14
    "t_insurance_name", //default selected 15
    "t_insurance_policy_id", //default selected 16
];
export const columnsNameList1 = [
    "full_name", //default selected 1
    "patient_gender", //default selected 2
    "dob", //default selected 3
    "practice_patient_type", //default selected 4
    "street1", //default selected 5
    "street2", //default selected 6
    "city", //default selected 7
    "state", //default selected 8
    "patient_zip", //default selected 9
    "home_phone", //default selected 10
    "p_insurance_name", //default selected 11
    "p_insurance_policy_id", //default selected 12
    "s_insurance_name", //default selected 13
    "s_insurance_policy_id", //default selected 14
    "t_insurance_name", //default selected 15
    "t_insurance_policy_id", //default selected 16
    "suffix", //1
    "dod", //2
    "ssn", //3
    "country", //4
    "work_phone", //5
];
export const columnsNameList2 = [
    "cell_phone", //6
    "email", //7
    "ethnicity", //8
    "race", //9
    "language_preferred", //10
    "rendering_provider", //11
    "service_location", //12
    "referring_provider", //13
    "referring_provider_group", //14
    "guarantor_last_name", //15
    "guarantor_first_name", //16
    "guarantor_middle_intial", //17
    "guarantor_suffix", //18
    "guarantor_relationship", //19
    "guarantor_street1", //20
    "guarantor_street2", //21
    "guarantor_city", //22
    "guarantor_state", //23
    "guarantor_zip", //24
    "guarantor_country", //25
    "guarantor_home_phone", //26
];
export const columnsNameList3 = [
    "guarantor_work_phone", //27
    "guarantor_cell_phone", //28
    "guarantor_email", //29
    "emergency_last_name", //30
    "emergency_first_name", //31
    "emergency_middle_intial", //32
    "emergency_suffix", //33
    "emergency_relationship", //34
    "emergency_street1", //35
    "emergency_street2", //36
    "emergency_city", //37
    "emergency_state", //38
    "emergency_zip", //39
    "emergency_country", //40
    "emergency_home_phone", //41
    "emergency_work_phone", //42
    "emergency_cell_phone", //43
    "emergency_email", //44
    "p_insurance_group_number", //45
    "p_effective_date", //46
    "p_term_date", //47
];
export const columnsNameList4 = [
    "p_copay", //48
    "p_coins", //49
    "p_ded", //50
    "p_out_of_pocket_max", //51
    "p_subscriber_relationship", //52
    "p_subscriber_Last_name", //53
    "p_subscriber_first_name", //54
    "p_subscriber_middle_initial", //55
    "p_subscriber_suffix", //56
    "p_subscriber_gender", //57
    "p_subscriber_dob", //58
    "p_subscriber_street1", //59
    "p_subscriber_street2", //60
    "p_subscriber_city", //61
    "p_subscriber_state", //62
    "p_subscriber_zip", //63
    "p_subscriber_country", //64
    "p_subscriber_phone", //65
    "s_insurance_group_number", //66
    "s_effective_date", //67
    "s_term_date", //68
];

export const columnsNameList5 = [
    "s_copay", //69
    "s_coins", //70
    "s_ded", //71
    "s_out_of_pocket_max", //72
    "s_subscriber_relationship", //73
    "s_subscriber_Last_name", //74
    "s_subscriber_first_name", //75
    "s_subscriber_middle_initial", //76
    "s_subscriber_suffix", //77
    "s_subscriber_gender", //78
    "s_subscriber_dob", //79
    "s_subscriber_street1", //80
    "s_subscriber_street2", //81
    "s_subscriber_city", //82
    "s_subscriber_state", //83
    "s_subscriber_zip", //84
    "s_subscriber_country", //85
    "s_subscriber_phone", //86
    "t_insurance_group_number", //87
    "t_effective_date", //88
    "t_term_date", //89
];

export const columnsNameList6 = [
    "t_copay", //90
    "t_coins", //91
    "t_ded", //92
    "t_out_of_pocket_max", //93
    "t_subscriber_relationship", //94
    "t_subscriber_Last_name", //95
    "t_subscriber_first_name", //96
    "t_subscriber_middle_initial", //97
    "t_subscriber_suffix", //98
    "t_subscriber_gender", //99
    "t_subscriber_dob", //100
    "t_subscriber_street1", //101
    "t_subscriber_street2", //102
    "t_subscriber_city", //103
    "t_subscriber_state", //104
    "t_subscriber_zip", //105
    "t_subscriber_country", //106
    "t_subscriber_phone", //107
];

export const PatientTableHeadData = [
    // value given as serializer field, but in patientListTable it may change
    { id: 1, value: "full_name", name: colPatientName },
    { id: 2, value: "custom_patient_id", name: colSelPID },
    { id: 3, value: "gender", name: colSelGENDER },
    { id: 4, value: "dob", name: colSelDOB },
    { id: 5, value: "patient_type", name: colSelPTYPE },
    { id: 6, value: "address", name: colSelADDRESS },
    { id: 7, value: "home_phone", name: colSelHOMEPHONE },
    { id: 8, value: "guarantor_name", name: colSelGUARANTOR },
    {
        id: 9,
        value: "guarantor_relationship",
        name: colSelGUARANTORRELATIONSHIP,
    },
    { id: 10, value: "guarantor_address", name: colSelGUARANTORADDRESS },
    { id: 11, value: "guarantor_home_phone", name: colSelGUARANTORHOMEPHONE },
    { id: 12, value: "emergency_name", name: colSelEMERGENCY },
    {
        id: 13,
        value: "emergency_relationship",
        name: colSelEMERGENCYRELATIONSHIP,
    },
    { id: 14, value: "emergency_address", name: colSelEMERGENCYADDRESS },
    { id: 15, value: "emergency_home_phone", name: colSelEMERGENCYHOMEPHONE },
    { id: 16, value: "p_insurance_name", name: colSelPINSURANCENAME },
    { id: 17, value: "p_insurance_policy_id", name: colSelPINSURANCEPOLICYID },
    { id: 18, value: "p_effective_date", name: colSelPEFFECTIVEDATE },
    {
        id: 19,
        value: "p_subscriber_relationship",
        name: colSelPSUBRELATIONSHIP,
    },
    { id: 20, value: "p_subscriber_name", name: colSelPSUBNAME },
    { id: 21, value: "p_subscriber_gender", name: colSelPSUBGENDER },
    { id: 22, value: "p_subscriber_dob", name: colSelPSUBDOB },
    { id: 23, value: "p_subscriber_address", name: colSelPSUBADDRESS },
];