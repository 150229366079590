// React imports
import React, { useContext, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

// External libraries
import { Box, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography, Alert, AlertTitle } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';

// Constants and contexts
import { PATIENT_STATEMENT_VENDORS } from '../../../utilities/staticConfigs';
import LoadingContext from '../../../container/loadingContext';

// Custom components
import CommonButton from '../../commons/Buttons';
import SelectInput from '../../commons/input/SelectInput';
import TextInput from '../../commons/input/input';

// Services
import service from './service';

// local
import i18n from '../../../utilities/i18n';

/**
 * PracticeStatementConfig Component
 * @component
 * @param {Object} props - The component props
 * @param {string} props.practicePk - The primary key of the practice (required)
 * @param {Function} props.showNotifyWindow - A function to show notification messages (required)
 * @returns {React.ReactElement} A form for configuring practice statement settings
 */
function PracticeStatementConfig({ practicePk, showNotifyWindow }) {
    // Max Date Allowed For Days of the Month Input
    const maxDayAllowed = useMemo(() => 28, []);

    // Loading bar context
    const setShowLoadingBar = useContext(LoadingContext);

    // Input state
    const [statementConfig, setStatementConfig] = useState({
        id: "",
        statement_vendor: "",
        day_of_the_month_for_statement_generation: null,
        interval_days_to_send_next_statement: null,
        num_of_statement_to_release_fully_paid: "",
        release_statement_against_patient: false,
        release_statement_against_claim: false,
        client_id_from_vendor: ""
    });

    // State to track if user wants the statement released in an every interval days or a given day of the month
    const [statementFrequencyType, setStatementFrequencyType] = useState("");

    // State to track if user wants to release the statement against a claim or patient level account
    const [statementReleaseTarget, setStatementReleaseTarget] = useState("");

    // On Component mount get evoke the api call to get the current db state
    useEffect(() => {
        getPracticeStatementConfig();
    }, [practicePk]);



    /**
     * Fetches the practice statement configuration from the service.
     * @async
     * @returns {Promise<void>} 
     * @throws {Error} Throws an error if the response does not contain a valid id.
     */
    const getPracticeStatementConfig = async () => {
        setShowLoadingBar(true);

        try {
            const response = await service.GetPracticeStatementConfig(practicePk);

            if (!response.data?.id) {
                throw new Error("An error occurred while fetching Practice Statement Configurations");
            }

            // Determine if the statement to be released every month or in a given interval days
            const getStatementFrequencyType = (config) => {
                if (config.day_of_the_month_for_statement_generation) return 'monthly';
                if (config.interval_days_to_send_next_statement) return 'interval';
                return '';
            }

            const statementFrequencyType = getStatementFrequencyType(response.data)
            setStatementFrequencyType(statementFrequencyType);

            // Determine if the statement release is against a patient or a claim based on the response
            const getReleaseType = (config) => {
                if (config.release_statement_against_claim) return 'claim';
                if (config.release_statement_against_patient) return 'patient';
                return 'none';
            };

            const releaseType = getReleaseType(response.data);
            setStatementReleaseTarget(releaseType);

            // Set the form state
            setStatementConfig({
                ...response.data
            });

        } catch (error) {
            showNotifyWindow('show', 'error', "An error occurred while fetching Practice Statement Configurations.");
            console.warn(error);
        } finally {
            setShowLoadingBar(false);
        }
    };



    /**
     * Handles input changes for the statement configuration.
     * @param {Event} e - The event object from the input change.
     */
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        let updatedValue = value === "" ? null : value;

        if (name === "num_of_statement_to_release_fully_paid") {
            // Only allow positive integers
            const positiveInteger = value.replace(/[^\d.]/g, '');
            updatedValue = positiveInteger === '' ? null : parseInt(positiveInteger, 10);
        }

        setStatementConfig((prevConfig) => ({
            ...prevConfig,
            [name]: updatedValue
        }));
    };


    /**
     * Handles changes in the statement frequency type (monthly or interval).
     * 
     * This function updates the statementFrequencyType state and adjusts the
     * statementConfig based on the selected frequency type. It ensures that
     * only the relevant field (monthlyStatementDay or statementIntervalDays)
     * is set while the other is nullified.
     * 
     * @param {React.ChangeEvent<HTMLInputElement>} e - The event object from the radio button change.
     * @throws {Error} Throws an error if an unexpected frequency type is selected.
     */
    const handleFrequencyTypeChange = (e) => {
        const selectedType = e.target.value;
        setStatementFrequencyType(selectedType);

        if (selectedType === 'monthly') {
            setStatementConfig((prevConfig) => ({
                ...prevConfig,
                day_of_the_month_for_statement_generation: prevConfig.monthlyStatementDay || 1,
                interval_days_to_send_next_statement: null
            }));
        } else if (selectedType === 'interval') {
            setStatementConfig((prevConfig) => ({
                ...prevConfig,
                day_of_the_month_for_statement_generation: null,
                interval_days_to_send_next_statement: prevConfig.statementIntervalDays || 1
            }));
        } else {
            throw new Error(`Unexpected frequency type: ${selectedType}`);
        }
    };



    /**
     * Handles changes to the monthly statement day input.
     * 
     * @param {React.ChangeEvent<HTMLInputElement>} e - The event object from the input change.
     */
    const handleMonthlyDayChange = (e) => {
        const positiveInteger = e.target.value.replace(/[^\d.]/g, '');
        const updatedValue = positiveInteger === '' ? null : parseInt(positiveInteger, 10);
        if (updatedValue === null || (updatedValue >= 1 && updatedValue <= maxDayAllowed)) {
            setStatementConfig((prevConfig) => ({
                ...prevConfig,
                day_of_the_month_for_statement_generation: updatedValue
            }));
        }
    };



    /**
     * Handles changes to the interval days input.
     * 
     * @param {React.ChangeEvent<HTMLInputElement>} e - The event object from the input change.
     */
    const handleIntervalDaysChange = (e) => {
        const positiveInteger = e.target.value.replace(/[^\d.]/g, '');
        const updatedValue = positiveInteger === '' ? null : parseInt(positiveInteger, 10);
        if (updatedValue === null || updatedValue >= 1) {
            setStatementConfig((prevConfig) => ({
                ...prevConfig,
                interval_days_to_send_next_statement: updatedValue
            }));
        }
    };


    /**
     * Handles changes to the radio button for release statement against claim or patient rule.
     *  
     * @param {React.ChangeEvent<HTMLInputElement>} e - The event object from the input change.
     */
    const handleReleaseTargetChange = (e) => {
        const target = e.target.value;
        setStatementReleaseTarget(target);

        setStatementConfig((prevConfig) => ({
            ...prevConfig,
            release_statement_against_patient: target === 'patient',
            release_statement_against_claim: target === 'claim'
        }));
    };


    /**
     * Validates the statement configuration before executing the API call for update.
     * @param {Object} config - The statement configuration to validate.
     * @returns {Object} An object with 'isValid' boolean and 'errorMessage' string if invalid.
     */
    const validateStatementConfig = (config) => {
        const {
            statement_vendor,
            day_of_the_month_for_statement_generation,
            interval_days_to_send_next_statement,
            num_of_statement_to_release_fully_paid,
            release_statement_against_patient,
            release_statement_against_claim
        } = config;

        // Validation Rule 1: If statement vendor is selected, either monthly or interval day must be provided, but not both
        if (statement_vendor) {
            if (day_of_the_month_for_statement_generation && interval_days_to_send_next_statement) {
                return {
                    isValid: false,
                    errorMessage: i18n.t('statementManagement.validationErrors.monthlyOrIntervalRequired')

                };
            }
            if (!day_of_the_month_for_statement_generation && !interval_days_to_send_next_statement) {
                return {
                    isValid: false,
                    errorMessage: i18n.t('statementManagement.validationErrors.eitherMonthlyOrIntervalRequired')
                };
            }
        }

        // Validation Rule 2: Number of statements to release until fully paid must be greater than 0
        if (statement_vendor && (num_of_statement_to_release_fully_paid <= 0 || num_of_statement_to_release_fully_paid === null)) {
            return {
                isValid: false,
                errorMessage: i18n.t('statementManagement.validationErrors.numStatementsGreaterThanZero')
            };
        }

        // Validation Rule 3: Either release against patient or release against claim must be selected, but not both
        if (statement_vendor) {
            if (release_statement_against_patient && release_statement_against_claim) {
                return {
                    isValid: false,
                    errorMessage: i18n.t('statementManagement.validationErrors.patientOrClaimReleaseRequired')
                };
            }
            if (!release_statement_against_patient && !release_statement_against_claim) {
                return {
                    isValid: false,
                    errorMessage: i18n.t('statementManagement.validationErrors.eitherPatientOrClaimReleaseRequired')
                };
            }
        }

        // Validation Rule 4: Day of the month for statement generation should not be greater than 27
        if (statement_vendor && day_of_the_month_for_statement_generation > maxDayAllowed) {
            return {
                isValid: false,
                errorMessage: i18n.t('statementManagement.validationErrors.dayOfMonthTooHigh', { maxDay: maxDayAllowed })
            };
        }

        return { isValid: true };
    };



    /**
     * @description Handle the save to db functionality
     * @async
     * @returns 
     */
    const handleSave = async () => {
        const validationResult = validateStatementConfig(statementConfig);

        if (!validationResult.isValid) {
            showNotifyWindow('show', 'error', validationResult.errorMessage);
            return;
        }

        setShowLoadingBar(true);

        try {
            const response = await service.UpdatePracticeStatementConfig(practicePk, statementConfig);
            if (response.data?.id) {
                showNotifyWindow('show', 'success', "Statement configuration updates successfully.");
            } else {
                throw new Error("An un-expected error occurred while updating the statement configuration.")
            }
        } catch (error) {
            showNotifyWindow('show', 'error', "An error occurred while updating Practice Statement Configurations.");
            console.warn(error);
        } finally {
            setShowLoadingBar(false)
        }
    }


    return (
        <FormControl>
            <Grid container direction="column" spacing={4}>
                <Grid item xs={4} sx={{ marginTop: '10px' }}>
                    <Typography variant="h5" component="h5">
                        {i18n.t("statementManagement.practiceRules.helperTextStatementRelease")}
                    </Typography>
                </Grid>

                <Grid item xs={4}>
                    <div style={{ maxWidth: '600px' }}>
                        <SelectInput
                            data={PATIENT_STATEMENT_VENDORS}
                            name="statement_vendor"
                            onValueChange={handleInputChange}
                            id="statement_vendor_select_box"
                            required={true}
                            value={statementConfig.statement_vendor}
                            label={i18n.t("statementManagement.practiceRules.statementVendor")}
                            className={!statementConfig.statement_vendor ? 'input-error' : ""}
                        />

                        {!statementConfig.statement_vendor && (
                            <Alert
                                severity="warning"
                                icon={<WarningIcon />}
                                sx={{ mt: 2, mb: 2 }}
                            >
                                <AlertTitle>{i18n.t("commons.warnings")}</AlertTitle>
                                {i18n.t("statementManagement.practiceRules.warning")}
                            </Alert>
                        )}

                        <TextInput
                            id="vendorClientId"
                            name="client_id_from_vendor"
                            label={i18n.t("statementManagement.practiceRules.clientId")}
                            onValueChange={handleInputChange}
                            value={statementConfig.client_id_from_vendor}
                        />

                        <TextInput
                            type="number"
                            id="num_of_statement_to_release_fully_paid"
                            name="num_of_statement_to_release_fully_paid"
                            required={true}
                            label={i18n.t("statementManagement.practiceRules.numStatementsTillFullyPaid")}
                            onValueChange={handleInputChange}
                            value={statementConfig.num_of_statement_to_release_fully_paid}
                        />

                    </div>
                </Grid>

                <Grid item xs={4}>
                    <div style={{ marginLeft: '15px' }}>
                        <Typography variant="h6">{i18n.t("statementManagement.practiceRules.statementIntervalRules")}</Typography>
                        <RadioGroup
                            aria-labelledby="statement-generation-options"
                            value={statementFrequencyType}
                            onChange={handleFrequencyTypeChange}
                        >
                            <Box display="flex" alignItems="center" mb={1}>
                                <FormControlLabel
                                    value="monthly"
                                    control={<Radio />}
                                    label={i18n.t("statementManagement.practiceRules.releaseOnGivenDay")}
                                />
                                <input
                                    type="number"
                                    value={statementConfig.day_of_the_month_for_statement_generation || ''}
                                    onChange={handleMonthlyDayChange}
                                    disabled={statementFrequencyType !== 'monthly'}
                                    min={1}
                                    max={maxDayAllowed}
                                    style={{ width: '60px', marginLeft: '2px', border: '1px solid #ccc', borderRadius: '4px', padding: '4px' }}
                                    placeholder={i18n.t("statementManagement.practiceRules.days")}
                                />
                                <Box component="span" ml={1}>{i18n.t("statementManagement.practiceRules.dayOfMonth")}</Box>
                            </Box>
                            <Box display="flex" alignItems="center">
                                <FormControlLabel
                                    value="interval"
                                    control={<Radio />}
                                    label={i18n.t("statementManagement.practiceRules.releaseOnInterval")}
                                />
                                <input
                                    type="number"
                                    value={statementConfig.interval_days_to_send_next_statement || ''}
                                    onChange={handleIntervalDaysChange}
                                    disabled={statementFrequencyType !== 'interval'}
                                    min={1}
                                    style={{ width: '60px', marginLeft: '2px', border: '1px solid #ccc', borderRadius: '4px', padding: '4px' }}
                                    placeholder={i18n.t("statementManagement.practiceRules.days")}
                                />
                                <Box component="span" ml={1}>{i18n.t("statementManagement.practiceRules.days").toLowerCase()}</Box>
                            </Box>
                        </RadioGroup>
                    </div>
                </Grid>

                <Grid item xs={4}>
                    <div style={{ marginLeft: '15px' }}>
                        <Typography variant="h6">{i18n.t("statementManagement.practiceRules.releaseOptions")}</Typography>
                        <div style={{ maxWidth: '300px' }}>
                            <RadioGroup
                                aria-labelledby="release-statement-options"
                                value={statementReleaseTarget}
                                onChange={handleReleaseTargetChange}
                                name="release-statement-options"
                            >
                                <FormControlLabel
                                    value="patient"
                                    control={<Radio />}
                                    label={i18n.t("statementManagement.practiceRules.releaseAgainstPatient")}
                                />
                                <FormControlLabel
                                    value="claim"
                                    control={<Radio />}
                                    label={i18n.t("statementManagement.practiceRules.releaseAgainstClaim")}
                                />
                                <FormControlLabel
                                    value="none"
                                    control={<Radio />}
                                    label={i18n.t("statementManagement.practiceRules.none")}
                                />
                            </RadioGroup>
                        </div>
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <div style={{ maxWidth: '600px', textAlign: 'right' }}>
                        <CommonButton
                            icon="update"
                            variant="contained"
                            label="Update"
                            id="practice-statement-rule-update"
                            onClick={handleSave}
                        />
                    </div>
                </Grid>
            </Grid>
        </FormControl>
    );
}

export default PracticeStatementConfig;


PracticeStatementConfig.propTypes = {
    practicePk: PropTypes.string.isRequired,
    showNotifyWindow: PropTypes.bool.isRequired,
};