import React, { useState, useEffect, useContext } from 'react';
import { format } from 'date-fns';

import service from './service';

import Pagination from '../../commons/pagination';
import TextInput from '../../commons/input/input';
import SelectInput from '../../commons/input/SelectInput';
import RangeInput from '../../commons/input/RangeInput';
import CalendarInput from '../../commons/input/CalendarInput';

import LoadingContext from '../../../container/loadingContext';
import CustomizedDialogs from "../../modalWindowComponent/CustomizedDialogs";
import { MaterialMultiselect } from '../../../MaterialMultiselect/MaterialMultiselect';

import i18n from '../../../utilities/i18n';
import { getStorage } from '../../../utilities/browserStorage';
import { YMD_TO_MDY } from '../../../utilities/commonUtilities';
import { ACTIVE_STATUS } from '../../../utilities/dictionaryConstants';
import { PAGING_END_INDEX, DEFAULT_PAGING_SIZE, CLAIMDATEOFSERVICERANGE } from '../../../utilities/staticConfigs';
import PatientsAdvancedSearchTable from './patientAdvanceSearchTable';
import Notify from '../../commons/notify';
import Label from '../../commons/Label';
import { useSelector, useDispatch } from 'react-redux';
import { getdropdownData, resetValue } from '../../../store/patient/PatientAdvanceSearch/PatientAdvanceSearchSlice';

const PatientsAdvancedSearch = (props) => {
    const { dropDownListData,
        isErrorGettingDropdownData,
        isFilterDropDownDataReceived } = useSelector((state) => state.patientAdvanceSearchData);
    const { dateTypeDos, dateTypePay, dos1, dos2, lastPaymentDate1, lastPaymentDate2,
        searchPatientID, lastNameStarts, firstNameStarts, dob1, dob2, patientBlnceBtwn1,
        patientBlnceBtwn2, insurBlnceBtwn1, insurBlnceBtwn2, activeStatus,
        insuranceValue, practiceLocations, facility, renderingProvider, referingProvider,
        patientType, } = useSelector((state) => state.patientAdvanceSearchData);
    const dispatch = useDispatch();
    const setShowLoadingBar = useContext(LoadingContext);

    const practicePK = getStorage("practice") ? parseInt(getStorage("practice")) : '';
    const [showAdvancedPatients, setShowAdvancedPatients] = useState(false);
    const patientApi1 = [{ "id": 1, "value": "custom_patient_id", "name": "Patient ID" }, { "id": 2, "value": "date_of_birth", "name": "DOB" }, { "id": 3, "value": "patient_type", "name": "Patient Type" }];
    const [AdvancedPatientList, setAdvancedPatientList] = useState([]);

    const [showNotify, setShowNotify] = useState('hide');
    const [notifyDescription, setNotifyDescription] = useState('');
    const [notifyType, setNotifyType] = useState('success');

    //Pagination start
    const [totalPage, setTotalPage] = useState(1);
    const [activePage, setActivePage] = useState(1);
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(PAGING_END_INDEX);
    const [initialTableData, setInitialTableData] = useState(i18n.t("commons.noRecords"));

    function onPagePrevious() {
        let previousPage = startIndex + 1 - PAGING_END_INDEX;
        setActivePage(previousPage);
        if (startIndex !== 0) {
            setStartIndex(startIndex - PAGING_END_INDEX);
            setEndIndex(endIndex - PAGING_END_INDEX);
        }
        onAdvanceSearchPatients(DEFAULT_PAGING_SIZE, previousPage);

    }

    function onPageUp(e) {
        let page = Number(e.target.id)
        setActivePage(page);
        onAdvanceSearchPatients(DEFAULT_PAGING_SIZE, page);
    }

    function onPageNext() {
        let nextPage = startIndex + 1 + PAGING_END_INDEX;
        if (endIndex === totalPage || totalPage <= PAGING_END_INDEX) {
            setActivePage(nextPage);
            setStartIndex(startIndex);
            setStartIndex(endIndex);
        } else {
            setActivePage(nextPage);
            setStartIndex(startIndex + PAGING_END_INDEX);
            setEndIndex(endIndex + PAGING_END_INDEX);
        }
        onAdvanceSearchPatients(DEFAULT_PAGING_SIZE, nextPage);
    }
    //Pagination ends

    function showNotifyWindow(action, type, desc, age = 3000) {
        if (action == 'show') {
            setTimeout(() => {
                setShowNotify('hide');
            }, age)
        }
        setShowNotify(action);
        setNotifyType(type);
        setNotifyDescription(desc);
    }

    function onHandleChange(e) {
        let name = e.target.name;
        let value = e.target.value;
        let trimmedValue = "";

        // Accept only numbers in patient id field
        if (name === 'searchPatientID' && !/^\d*$/.test(value)) {
            return
        }

        let inputFields = ["searchValue", "lastNameStarts", "firstNameStarts", "patientBlnceBtwn1",
            "patientBlnceBtwn2", "insurBlnceBtwn1", "insurBlnceBtwn2", "activeStatus", "searchPatientID"];
        if (inputFields.includes(name)) {
            trimmedValue = value;
        }
        if (name.endsWith("Btwn1") || name.endsWith("Btwn2")) {
            dispatch({ type: "patientAdvanceSearchSlice/handleChange", payload: { name: e.target.name, value: trimmedValue.replace(/[^0-9.]/g, '') } });
        }
        else {
            dispatch({ type: "patientAdvanceSearchSlice/handleChange", payload: { name: e.target.name, value: trimmedValue } });
        }
    }

    function onHandleDOB1Change(selected) {
        dispatch({ type: "patientAdvanceSearchSlice/onHandleDOB1Change", payload: { selected: selected } });
    }

    function onHandleDOB2Change(selected) {
        dispatch({ type: "patientAdvanceSearchSlice/onHandleDOB2Change", payload: { selected: selected } });
    }

    function onHandleDateType(selValue, dateType) {
        if (dateType == 'DOS') {
            dispatch({ type: "patientAdvanceSearchSlice/onHandleDateType", payload: { dateType: 'DOS', value: selValue.target.value } });
        }
        else if (dateType == 'PAY') {
            dispatch({ type: "patientAdvanceSearchSlice/onHandleDateType", payload: { dateType: 'PAY', value: selValue.target.value } });
        }
    }

    function onHandleChangeDos1(selected) {
        dispatch({ type: "patientAdvanceSearchSlice/onHandleChangeDos1", payload: { selected: selected } });
    }

    function onHandleChangeDos2(selected) {
        dispatch({ type: "patientAdvanceSearchSlice/onHandleChangeDos2", payload: { selected: selected } });
    }

    function onHandleLastPaymentDate1(selected) {
        dispatch({ type: "patientAdvanceSearchSlice/onHandleLastPaymentDate1", payload: { selected: selected } });
    }

    function onHandleLastPaymentDate2(selected) {
        dispatch({ type: "patientAdvanceSearchSlice/onHandleLastPaymentDate2", payload: { selected: selected } });
    }


    useEffect(() => {
        if (!isFilterDropDownDataReceived) {
            // Dispatch Redux function to get the drop-down list data if it not there already in the state
            dispatch(getdropdownData());
        }
    }, []);

    // Display error notification if any error occurred while fetching filter dropdown list data
    useEffect(() => {
        if (isErrorGettingDropdownData === true)
            showNotifyWindow('show', 'error', i18n.t('errorMessages.error_getting_dropdown_data'));
    }, [isErrorGettingDropdownData]);

    async function onAdvanceSearchPatients(pageSize, page) {
        if (patientBlnceBtwn1 !== '' && patientBlnceBtwn2 !== '' && parseFloat(patientBlnceBtwn1) > parseFloat(patientBlnceBtwn2)) {
            return;
        }
        if (insurBlnceBtwn1 !== '' && insurBlnceBtwn2 !== '' && parseFloat(insurBlnceBtwn1) > parseFloat(insurBlnceBtwn2)) {
            return;
        }
        //created a helper function
        const areDatesOrdered = (date1, date2) => date2 && date2 < date1;

        if (areDatesOrdered(dob1, dob2) || areDatesOrdered(dos1, dos2) || areDatesOrdered(lastPaymentDate1, lastPaymentDate2)) {
            showNotifyWindow('show', 'error', i18n.t("commons.dateOrder"));
        } else {
            if (props.setShowAdvancedSearchTab)
                props.setShowAdvancedSearchTab(false);
            setShowLoadingBar(true);
            let query = await queryBuilder()
            if (props.setAdvancedQuery)
                props.setAdvancedQuery(query);
            query += '&page_size=' + pageSize + '&page=' + page;
            const result = service.ListPatients(query);
            result.then(response => {
                setShowLoadingBar(false);
                if (response?.data?.results !== undefined) {
                    if (props.setTotalPage) {
                        props.setTotalPage(Math.ceil(response.data.count / response.data.page_size));
                        props.setActivePage(activePage);
                    }
                    else {
                        setTotalPage(Math.ceil(response.data.count / response.data.page_size));
                    }
                }
                if (props.setPatientAdvList) {
                    props.setPatientAdvList(response.data.results);
                    props.setShowAdvancedSearchTab(true);
                    if (response?.data?.results?.length === 0)
                        if (props.setInitialTableData) {
                            props.setInitialTableData(i18n.t('commons.noRecords'));
                            props.setInitialTableData(i18n.t('commons.noRecords'))
                        }

                } else {
                    if (props.setInitialTableData) {
                        setInitialTableData(i18n.t("commons.noRecords"));
                    }
                    setAdvancedPatientList(response.data.results);
                    setShowAdvancedPatients(true);
                }
            });

            if (props?.secondSearchModule) {
                document.querySelector(`[aria-label=${props.secondSearchModule}]`).classList.toggle('active');
            }
            else {
                document.getElementById("right-content").classList.remove('active');
            }
        }
    }

    //building advance search query here
    function queryBuilder() {
        const queryParts = {
            '?column': 'full_name',
            'list_type': 'list',
            'practice_pk': practicePK,
            'filter': 1,
            'dos_from': dos1,
            'dos_to': dos2,
            'last_payment_date_1': lastPaymentDate1,
            'last_payment_date_2': lastPaymentDate2
        };

        if (searchPatientID.trim()) {
            queryParts.patient_id = searchPatientID.trim();
        }
        if (lastNameStarts.trim()) {
            queryParts.last_name = lastNameStarts.trim();
        }
        if (firstNameStarts.trim()) {
            queryParts.first_name = firstNameStarts.trim();
        }
        if (dob1) {
            queryParts.dob_from = format(dob1, 'yyyy-MM-dd') ?? "";
        }
        if (dob2) {
            queryParts.dob_to = format(dob2, 'yyyy-MM-dd') ?? "";
        }
        if (insuranceValue.length > 0) {
            queryParts.insurances = JSON.stringify(insuranceValue);
        }
        if (practiceLocations.length > 0) {
            queryParts.practice_locations = JSON.stringify(practiceLocations);
        }
        if (facility.length > 0) {
            queryParts.facilities = JSON.stringify(facility);
        }
        if (patientType.length > 0) {
            queryParts.patient_types = JSON.stringify(patientType);
        }
        if (renderingProvider.length > 0) {
            queryParts.rendering_providers = JSON.stringify(renderingProvider);
        }
        if (referingProvider.length > 0) {
            queryParts.referring_providers = JSON.stringify(referingProvider);
        }
        if (dos1) {
            queryParts.dos_from = format(dos1, 'yyyy-MM-dd') ?? "";
        }
        if (dos2) {
            queryParts.dos_to = format(dos2, 'yyyy-MM-dd') ?? "";
        }
        if (lastPaymentDate1) {
            queryParts.last_payment_date_1 = format(lastPaymentDate1, 'yyyy-MM-dd') ?? "";
        }
        if (lastPaymentDate2) {
            queryParts.last_payment_date_2 = format(lastPaymentDate2, 'yyyy-MM-dd') ?? "";
        }
        if (activeStatus) {
            queryParts.active_status = activeStatus;
        }
        if (patientBlnceBtwn1) {
            queryParts.patient_balance_from = patientBlnceBtwn1;
        }
        if (patientBlnceBtwn2) {
            queryParts.patient_balance_to = patientBlnceBtwn2;
        }
        if (insurBlnceBtwn1) {
            queryParts.insurance_balance_from = insurBlnceBtwn1;
        }
        if (insurBlnceBtwn2) {
            queryParts.insurance_balance_to = insurBlnceBtwn2;
        }

        // Delete falsy or null entries from queryParts
        for (const key in queryParts) {
            if (!queryParts[key]) {
                delete queryParts[key];
            }
        }

        const query = Object.entries(queryParts)
            .map(([key, value]) => value !== undefined && `${key}=${encodeURIComponent(value)}`)
            .filter(Boolean)
            .join('&');
        return query;
    }

    function onClearSearch() {
        dispatch(resetValue());
        setShowLoadingBar(true);
        setActivePage(1);
        setTotalPage(1);
        setStartIndex(0);
        setShowLoadingBar(false);
    }

    function resetAdvancedSearchData() {
        // resetAdvancedSearch();
    }

    function setPatientSelection(e, item) {
        props.setPatientSelected([{ 'id': item.id, 'name': item.name + ' (' + item.custom_patient_id + ')', dob: YMD_TO_MDY(item.date_of_birth), "label": item.name + ' (' + item.custom_patient_id + ')' + ' - ' + YMD_TO_MDY(item.date_of_birth) }])
        if (props.setSelectPatient) {
            props.setSelectPatient(item.name);
        }
        props.setPatientPK(item.id);
        if (props?.setPatientAdvSearchData) {
            let dob = YMD_TO_MDY(item.date_of_birth);
            let patientData = [{ "id": item.id, "dob": dob, "label": item.name + ' (' + item.custom_patient_id + ')' + ' - ' + dob, "name": item.name + ' (' + item.custom_patient_id + ')' }];
            props.setPatientAdvSearchData(patientData);
        }
        setShowAdvancedPatients(false);
    }
    function MaterialMultiSelectHandle(e) {
        let name = e.target.name;
        let value = e.target.value;
        dispatch({ type: "patientAdvanceSearchSlice/handleMultiSelectChange", payload: { name: name, value: value } });
    }

    return (
        <React.Fragment>
            <Notify showNotify={showNotify} setShowNotify={setShowNotify} notifyDescription={notifyDescription} setNotifyType={setNotifyType} setNotifyDescription={setNotifyDescription} notifyType={notifyType} />
            <div className="form-group  padding-top mt-">
                <TextInput type="text" id="searchPatientID" name="searchPatientID" value={searchPatientID} onValueChange={onHandleChange} label={i18n.t("patientPages.patients.labelPatientID")} />
            </div>
            <div className='row'>
                <div className=" col-6 form-group  padding-top mt-">
                    <TextInput type="text" id="lastNameStarts" name="lastNameStarts" value={lastNameStarts} onValueChange={onHandleChange} label={i18n.t("patientPages.patients.labelLastNameStarts")} />
                </div>
                <div className=" col-6 form-group padding-top mt-">
                    <TextInput type="text" id="firstNameStarts" name="firstNameStarts" value={firstNameStarts} onValueChange={onHandleChange} label={i18n.t("patientPages.patients.firstNameStarts")} />
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <CalendarInput name="dob1" selected={dob1} onValueChange={onHandleDOB1Change} label={i18n.t("patientPages.patients.labeldobBetween")} />
                </div>
                <div className="col-6 padding-top6 pl-12">
                    <CalendarInput name="dob2" minDate={dob1} selected={dob2} onValueChange={onHandleDOB2Change} id="dob2" />
                </div>
            </div>
            <div className="row">
                <div className="col-6 pb-3">
                    <MaterialMultiselect name={"insuranceValue"} value={insuranceValue} onValueChange={MaterialMultiSelectHandle}
                        options={dropDownListData.InsuranceCompany} label={i18n.t("patientPages.patients.labelInsurance")} />
                </div>
                <div className="col-6 pb-3">
                    <MaterialMultiselect id="practiceLocations" name="practiceLocations" value={practiceLocations} onValueChange={MaterialMultiSelectHandle}
                        options={dropDownListData.practiceLocation} label={i18n.t("patientPages.patients.labelClaimPracticeLocation")}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-6 pb-3">
                    <MaterialMultiselect id="facility" name="facility" value={facility} onValueChange={MaterialMultiSelectHandle}
                        options={dropDownListData.facilityGroup} label={i18n.t("patientPages.patients.labelClaimFacility")}
                    />
                </div>
                <div className="col-6 pb-3">
                    <MaterialMultiselect id="rendering_provider" name="renderingProvider" value={renderingProvider} onValueChange={MaterialMultiSelectHandle}
                        options={dropDownListData.renderingProvider} label={i18n.t("patientPages.patients.RenderingProvider")}
                    />
                </div>
            </div>
            <div className='row'>
                <div className="col-6 pb-3">
                    <MaterialMultiselect id="referingProvider" name="referingProvider" value={referingProvider} onValueChange={MaterialMultiSelectHandle}
                        options={dropDownListData.refferingProvider} label={i18n.t("patientPages.patients.referingProvider")}
                    />
                </div>
                <div className="col-6 pb-3">
                    <MaterialMultiselect id="patientType" name="patientType" value={patientType} onValueChange={MaterialMultiSelectHandle}
                        options={dropDownListData.patientType} label={i18n.t("patientPages.patients.labelPatientType")}
                    />
                </div>
            </div>
            <Label label={i18n.t("patientPages.patients.lastClaimdosBetween")} />
            <div className="form-group padding-top4">
                <SelectInput data={CLAIMDATEOFSERVICERANGE} id="dateTypeDos" name='dateTypeDos' value={dateTypeDos} onValueChange={(e) => onHandleDateType(e, 'DOS')} className={"date-filter-select"} selectOptionRemove={true} />
            </div>
            <div className="row">
                <div className="col-6">
                    <CalendarInput name="dos1" selected={dos1} onValueChange={onHandleChangeDos1} placeholder="From" noLabel={true} />
                </div>
                <div className="col-6">
                    <CalendarInput name="dos2" minDate={dos1} selected={dos2} onValueChange={onHandleChangeDos2} placeholder="To" noLabel={true} />
                </div>
            </div>
            <Label label={i18n.t("patientPages.patients.lastPaymentDate")} />
            <div className="form-group">
                <SelectInput data={CLAIMDATEOFSERVICERANGE} id="dateTypePay" name='dateTypePay' value={dateTypePay} onValueChange={(e) => onHandleDateType(e, 'PAY')} className={"date-filter-select"} selectOptionRemove={true} />
            </div>
            <div className="row">
                <div className="col-6">
                    <CalendarInput name="lastPaymentDate1" selected={lastPaymentDate1} onValueChange={onHandleLastPaymentDate1} placeholder="From" noLabel={true} />
                </div>
                <div className="col-6">
                    <CalendarInput name="lastPaymentDate2" minDate={lastPaymentDate1} selected={lastPaymentDate2} onValueChange={onHandleLastPaymentDate2} placeholder="To" noLabel={true} />
                </div>
            </div>
            <div >
                <RangeInput id1="patientBlnceBtwn1" id2="patientBlnceBtwn2" name1="patientBlnceBtwn1" name2="patientBlnceBtwn2" value1={patientBlnceBtwn1} value2={patientBlnceBtwn2} onValueChange1={onHandleChange} onValueChange2={onHandleChange}
                    ValidationMsg={'Patient balance two is less than patient balance one'} label={i18n.t("patientPages.patients.patientBlnceBetween")} />
            </div>
            <div >
                <RangeInput id1="insurBlnceBtwn1" id2="insurBlnceBtwn2" name1="insurBlnceBtwn1" name2="insurBlnceBtwn2" value1={insurBlnceBtwn1} value2={insurBlnceBtwn2} onValueChange1={onHandleChange} onValueChange2={onHandleChange}
                    ValidationMsg={'Insurance balance two is less than insurance balance one'} label={i18n.t("patientPages.patients.insurBlnceBtwn")} />
            </div>
            <div className="form-group">
                <SelectInput defaultValue={true} data={ACTIVE_STATUS} name="activeStatus" value={activeStatus} onValueChange={onHandleChange} label={i18n.t("patientPages.patients.labelActiveStatus")} />
            </div>
            <div className="d-flex justify-right pb-4">
                <div className="justify-right">
                    <button type="button" id="btn-clear" data-testid="btn-clear" onClick={() => onClearSearch()} className="btn btn-outline-primary btn-common-height35 margin-top1 margin-right15">
                        {i18n.t("commons.clear")}
                    </button>
                    <button type="button" id="advanceSearch" data-testid="advanceSearch" onClick={() => onAdvanceSearchPatients(DEFAULT_PAGING_SIZE, activePage)} className="btn btn-primary-blue btn-common-height35">
                        {i18n.t("patientPages.patients.Search")}
                    </button>
                </div>
            </div>
            <CustomizedDialogs showModal={showAdvancedPatients} header={i18n.t("commons.patientsList")} type="patientsList" resetForm={resetAdvancedSearchData} setShowModalWindow={setShowAdvancedPatients}>
                <div className="table">
                    <div className='tableRow'>
                        <PatientsAdvancedSearchTable AdvancedPatientList={AdvancedPatientList} patientApi1={patientApi1} setPatientSelection={setPatientSelection} initialTableData={initialTableData} />
                    </div>
                    <Pagination totalPage={totalPage} activePage={activePage} startIndex={startIndex} endIndex={endIndex} onPagePrevious={onPagePrevious} onPageUp={onPageUp} onPageNext={onPageNext} />
                    {totalPage <= 1 && <div className="clear-b-scroll-sector">&nbsp;</div>}
                </div>
            </CustomizedDialogs>
        </React.Fragment >
    )
}
export default PatientsAdvancedSearch;