import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getStorage } from "../../../utilities/browserStorage";
import service from "../../../components/patientManagement/patients/service";
import service1 from '../../../components/userManagement/practices/service'
import { DATE_FILTER_IDS } from "../../../utilities/staticConfigs";
import { filterDateValues } from "../../../utilities/dateFilterCalculation";
import {
    colPatientName, colSelPID,
    colSelDOB,
    colSelPTYPE,
    colSelACTION, } from "../../../components/patientManagement/patients/PatientLists/constants";

/**
 * reducer initial state value
 */
const initialState = {
    dropDownListData: {
        patientType: [],
        refferingProvider: [],
        renderingProvider: [],
        facilityGroup: [],
        practiceLocation: [],
        InsuranceCompany: [],
    },
    isLoading: false,
    isErrorGettingDropdownData: false,
    isDropDownDataReceived: false,
    searchPatientID: "",
    lastNameStarts: "",
    firstNameStarts: "",
    dob1: "",
    dob2: "",
    insuranceValue:[],
    practiceLocations: [],
    facility: [],
    renderingProvider: [],
    referingProvider: [],
    patientType: [],
    dos1: "",
    dos2: "",
    lastPaymentDate1: "",
    lastPaymentDate2: "",
    patientBlnceBtwn1: "",
    patientBlnceBtwn2: "",
    insurBlnceBtwn1: "",
    insurBlnceBtwn2: "",
    activeStatus: "",
    dateTypeDos:DATE_FILTER_IDS.custom,
    dateTypePay: DATE_FILTER_IDS.custom,
    insuranceBetweenError: false,
    patientBalanceBetweenError: false,
    tableHeaderSelected: [
        colPatientName,
        colSelPID,
        colSelDOB,
        colSelPTYPE,
        colSelACTION,
    ],
    defaultTableSelectorValues: [
        colPatientName,
        colSelPID,
        colSelDOB,
        colSelPTYPE,
        colSelACTION,
    ],
}


/*
 * Function getDropDownListData fetches all the drop-down-data required for the page in one shot using promise chaining 
 */
export const getdropdownData = createAsyncThunk(
    "advanceSearch/dropdownData",
    async (payload, thunkAPI) => {
        const pageSize = 20;
        const pageNum = 0;
        const practicePK = getStorage('practice');

        const promises = [
            service.ListPatientTypes(pageSize, pageNum, practicePK).then(response => response.data),
            service.ListReferringProviders(pageSize, pageNum, practicePK).then(response => response.data),
            service.ListRenderingProviders(pageSize, pageNum, practicePK).then(response => response.data),
            service.ListFacilities(pageSize, pageNum, practicePK).then(response => response.data),
            service1.ListPracticeLocations(pageSize, pageNum, practicePK).then(response => response.data),
            service.ListInsuranceNames(pageSize, pageNum, practicePK).then(response => response.data),
        ];
        try {
            const [
                patientType,
                refferingProvider,
                renderingProvider,
                facilityGroup,
                practiceLocation,
                InsuranceCompany,
            ] = await Promise.all(promises);

            return {
                patientType,
                refferingProvider,
                renderingProvider,
                facilityGroup,
                practiceLocation,
                InsuranceCompany,
            };
        } catch (error) {
            return thunkAPI.rejectWithValue({ errorMessage: 'Error getting Drop-down list data!' })
        }
    })

/**
 * api call for get selected patient Details
 * @param {*} payload
 */

//create slice for storing patient data
export const patientAdvanceSearchSlice = createSlice({
    name: "patientAdvanceSearchSlice",
    initialState,
    reducers: {

        handleChange: (state, actions) => {
            if (actions.payload.name == "insurBlnceBtwn2") {
                if (parseFloat(actions.payload.value) < parseFloat(state.insurBlnceBtwn1)) {
                    state.insuranceBetweenError = true;
                }
                else {
                    state.insuranceBetweenError = false;
                }
            }
            if (actions.payload.name == "patientBlnceBtwn2") {
                if (parseFloat(actions.payload.value) < parseFloat(state.patientBlnceBtwn1)) {
                    state.patientBalanceBetweenError = true;
                }
                else {
                    state.patientBalanceBetweenError = false;
                }
            }
          state[actions.payload.name]=actions.payload.value
        },

        handleMultiSelectChange: (state, actions) => {
        state[actions.payload.name]=actions.payload.value     
        },

        onHandleDOB1Change: (state, actions) => {
            state.dob1=actions.payload.selected
        },

        onHandleDOB2Change: (state, actions) => {
            state.dob2 = actions.payload.selected
        },

        onHandleDateType: (state, actions) => {
            if (actions.payload.dateType == 'DOS') {
                state.dateTypeDos = actions.payload.value;
                let dateObj = filterDateValues(actions.payload.value);
                state.dos1 = dateObj.fromDate;
                state.dos2 = dateObj.toDate;
            }
            else {
                state.dateTypePay = actions.payload.value;
                let dateObj = filterDateValues(actions.payload.value);
                state.lastPaymentDate1 = dateObj.fromDate;
                state.lastPaymentDate2 = dateObj.toDate
            }
        },

        onHandleChangeDos1: (state, actions) => {
            state.dos1=actions.payload.selected
        },

        onHandleChangeDos2: (state, actions) => {
            state.dos2 = actions.payload.selected
        },

        onHandleLastPaymentDate1: (state, actions) => {
           state.lastPaymentDate1=actions.payload.selected
        },

         onHandleLastPaymentDate2: (state, actions) => {
             state.lastPaymentDate2 = actions.payload.selected
        },
         
        patientHeaderSelectedChange: (state, actions) => {
            state.tableHeaderSelected = actions.payload.selected; 
        },
         
        resetValue: (state) => {
            state.searchPatientID = "";
            state.lastNameStarts = "";
            state.firstNameStarts = "";
            state.dob1 = "";
            state.dob2 = "";
            state.insuranceValue = [];
            state.practiceLocations = [];
            state.facility = [];
            state.renderingProvider = [];
            state.referingProvider = [];
            state.patientType = [];
            state.dos1 = "";
            state.dos2 = "";
            state.lastPaymentDate1 = "";
            state.lastPaymentDate2 = "";
            state.patientBlnceBtwn1 = "";
            state.patientBlnceBtwn2 = "";
            state.insurBlnceBtwn1 = "";
            state.insurBlnceBtwn2 = "";
            state.activeStatus = "";
            state.dateTypeDos = DATE_FILTER_IDS.custom;
            state.dateTypePay = DATE_FILTER_IDS.custom;
            state.patientBalanceBetweenError = false;
            state.insuranceBetweenError = false;
         }
    },
    extraReducers: (builder) => {
        /**
       * Extra-reducers for api data fetch functions - (getDropDownListData)
       */
        builder
            .addCase(getdropdownData.pending, (state) => {
                state.isLoading = true;
                state.isErrorGettingDropdownData = false;
            })
            .addCase(getdropdownData.fulfilled, (state, action) => {
                const { patientType,
                    refferingProvider,
                    renderingProvider,
                    facilityGroup,
                    practiceLocation,
                    InsuranceCompany, } = action.payload;
                state.dropDownListData.patientType = patientType;
                state.dropDownListData.refferingProvider = refferingProvider;
                state.dropDownListData.renderingProvider = renderingProvider;
                state.dropDownListData.facilityGroup = facilityGroup;
                state.dropDownListData.practiceLocation = practiceLocation;
                state.dropDownListData.InsuranceCompany = InsuranceCompany;
                state.isLoading = false;
                state.isErrorGettingDropdownData = false;
                state.isDropDownDataReceived = true
            })
            .addCase(getdropdownData.rejected, (state) => {
                state.isLoading = false;
                state.isErrorGettingDropdownData = true;
            });
    }
})


export const {
    handleMultiSelectChange, handleChange, onHandleDOB1Change, onHandleDOB2Change, onHandleDateType, resetValue, patientHeaderSelectedChange
} = patientAdvanceSearchSlice.actions
export default patientAdvanceSearchSlice.reducer;
