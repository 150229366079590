import api from "../../../service/api";

const searchPatientStatement = (praticeId, patientId) => {
  const path =
    "patient/patient-statement-detail/?patient_pk=" +
    patientId +
    "&practice_pk=" +
    praticeId;
    return api.get(path);
};

const setStatementConfig = (body) => {
  const path = `practice/statement-configuration/`;
  return api.postUpload(path, body);
};

const getStatementConfig = (practicePK) => {
  const path = `practice/get-statement-template-configuration-details/?practice_pk=${practicePK}`;
  return api.get(path);
};

const updateStatementConfig = (id, payload) => {
  const path = `/practice/update-statement-template-configuration-detail/${id}`;
  return api.put(path, payload);
};

const saveTemplateConfig = (payload) => {
  const path = `/practice/create-statement-template-configuration-detail/`;
  return api.post(path, payload);
};



const ListClaimsDropDown = async (queryString, patient_pk, practice_pk, page = 0) => {
  const encodedQuery = encodeURIComponent(queryString);
  const path = `claim/claims/?drop_down=true&search=${encodedQuery}&page=${page}&filter=1&patient_pk=${patient_pk}&practice_pk=${practice_pk}`;

  return await api.get(path);
};

// Debounce function
const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    return new Promise((resolve) => {
      timeoutId = setTimeout(() => {
        resolve(func(...args));
      }, delay);
    });
  };
};

// Debounced version of ListClaimsDropDown
const debouncedListClaimsDropDown = debounce(ListClaimsDropDown, 300);

/**
 * Fetches statement release tracker data based on provided filters
 * @param {Object} filters - The filters to apply to the request
 * @param {string} filters.practice_pk - The practice primary key
 * @param {number} filters.page - The page number
 * @param {number} filters.page_size - The number of items per page
 * @param {string} [filters.release_date_from] - Optional release date filter
 * * @param {string} [filters.release_date_to] - Optional release date filter
 * @param {string} [filters.batch_id] - Optional batch ID filter
 * @param {string} [filters.patient_pk] - Optional patient primary key filter
 * @param {string} [filters.claim_pk] - Optional claim primary key filter
 * @param {string} [filters.statement_vendor] - Optional statement vendor filter
 * @returns {Promise} A promise that resolves with the API response
 */
const getStatementReleaseTrackerData = async (filters) => {
  const {
    practice_pk,
    page,
    page_size,
    release_date_from,
    release_date_to,
    batch_id,
    patient_pk,
    claim_pk,
    statement_vendor,
    status,
    is_export
  } = filters;

  const params = new URLSearchParams({
    practice_pk,
    page: page.toString(),
    page_size: page_size.toString(),
    list_type: is_export ? null : "list",
    ...(is_export && { export: is_export }),
    ...(status && { status }),
    ...(release_date_from && { release_date_from }),
    ...(release_date_to && { release_date_to }),
    ...(batch_id && { batch_id }),
    ...(patient_pk && { patient_pk }),
    ...(claim_pk && { claim_pk }),
    ...(statement_vendor && { statement_vendor }),
  });

  return await api.get(`patient/get-statement-release-data/?${params.toString()}`);
};



const getStatementBatchFileUrl = async (reqData) => {
  return await api.post("patient/get-statement-batch-file-uri/", reqData)
}

const getStatementPdfUrl = async (reqData) => {
  return await api.post("patient/get-vendor-statement-pdf-file-uri/", reqData)
}


const getStatementManagementTableData = async (filters) => {
  const {
    practice_pk,
    page,
    page_size,
    patient_pk,
    dos_from,
    dos_to,
    entered_from,
    entered_to,
    insurances,
    claim_balance_from,
    claim_balance_to,
    providers,
    facilities,
    facility_types,
    bill_pr_codes,
    claim_status,
    statement_vendor
  } = filters;

  const params = new URLSearchParams({
    practice_pk,
    page: page.toString(),
    page_size: page_size.toString(),
    list_type: "list",
    ...(patient_pk && { patient_pk }),
    ...(dos_from && { dos_from }),
    ...(dos_to && { dos_to }),
    ...(entered_from && { entered_from }),
    ...(entered_to && { entered_to }),
    ...(insurances && { insurances }),
    ...(claim_balance_from && { claim_balance_from }),
    ...(claim_balance_to && { claim_balance_to }),
    ...(providers && { providers }),
    ...(facilities && { facilities: facilities.map(item => item.split("||")[0]).join(',') }),
    ...(facility_types && { facility_types: facilities.map(item => item.split("||")[1]).join(',') }),
    ...(bill_pr_codes && { bill_pr_codes }),
    ...(claim_status && { claim_status }),
    ...(statement_vendor && { statement_vendor }),
  });

  return await api.get(`patient/patient-statement/?${params.toString()}`);
}


/**
 * Queues selected patient statements for automated release.
 * @async
 * @param {number[]} patientIds - An array of patient IDs to be queued for statement release.
 */
const queueStatementsForAutomatedRelease = async (patientIds) => {
  return await api.post("patient/send-patient-statement/", { patient_pks: patientIds });
};

/**
 * Print patient statements pdf for selected patients
 * @param {Array<number>} patientIds - Array of patient PKs to print statements for
 * @param {number} practicePk - Practice primary key
 * @returns {Promise} API response
 */
const printStatementInTemplate = async (patientIds, practicePk) => {
  try {
    const params = new URLSearchParams({
      practice_pk: practicePk,
      patient_pks: JSON.stringify(patientIds),
    });

    const response = await api.getFile(`patient/print-patient-statement/?${params.toString()}`)

    return response;
  } catch (error) {
    console.error('Error printing statements:', error);
    throw error;
  }
};

export default {
  searchPatientStatement,
  setStatementConfig,
  getStatementConfig,
  updateStatementConfig,
  ListClaimsDropDown,
  debouncedListClaimsDropDown,
  getStatementReleaseTrackerData,
  getStatementBatchFileUrl,
  getStatementManagementTableData,
  queueStatementsForAutomatedRelease,
  saveTemplateConfig,
  printStatementInTemplate,
  getStatementPdfUrl
};
