// React imports
import React, { useState } from 'react';
import PropTypes from 'prop-types';
// Icons
import CreateRoundedIcon from '@mui/icons-material/CreateRounded';

/**
 * EditorIconWrapper Component
 * 
 * A wrapper component that adds an edit icon overlay to any content when in edit mode.
 * Provides visual feedback through hover effects and handles edit interactions.
 * 
 * @component
 * @param {Object} props - Component props
 * @param {React.ReactNode} props.children - The content to be wrapped with the edit icon
 * @param {Function} props.onEdit - Callback function triggered when edit icon is clicked
 * @param {('small'|'medium'|'large')} [props.iconSize='small'] - Size of the edit icon
 * @param {string} [props.iconColor='primary'] - Color of the edit icon, uses MUI color system
 * @param {boolean} [props.disabled=false] - Whether the edit functionality is disabled
 * @param {boolean} [props.editEnabled=false] - Whether edit mode is enabled globally
 * @returns {JSX.Element} The wrapped content with or without edit functionality
 */
function EditorIconWrapper({
    children,
    onEdit,
    iconSize = "small",
    iconColor = "primary",
    disabled = false,
    editEnabled = false
}) {
    const [isHovered, setIsHovered] = useState(false);

    if (!editEnabled) {
        return children;
    }

    const wrapperStyles = {
        position: 'relative',
        display: 'inline-block',
        width: 'fit-content',
        padding: '2px',
        border: isHovered && !disabled ? '2px dashed #1976d2' : '2px solid transparent',
        borderRadius: '4px',
        transition: 'all 0.2s ease-in-out',
        cursor: disabled ? 'default' : 'pointer',
    };

    const editIconStyles = {
        position: 'absolute',
        top: '-12px',
        right: '-12px',
        cursor: disabled ? 'default' : 'pointer',
        opacity: disabled ? 0.5 : 1,
        backgroundColor: 'white',
        borderRadius: '50%',
        padding: '2px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: isHovered && !disabled
            ? '0 4px 8px rgba(0,0,0,0.2)'
            : '0 2px 4px rgba(0,0,0,0.1)',
        transform: isHovered && !disabled ? 'scale(1.1)' : 'scale(1)',
        transition: 'all 0.2s ease-in-out'
    };

    const iconStyles = {
        transition: 'all 0.2s ease-in-out',
        transform: isHovered && !disabled ? 'rotate(-10deg)' : 'rotate(0)'
    };

    return (
        <div
            style={wrapperStyles}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={disabled ? undefined : onEdit}
            role='button'
        >
            {children}
            <div
                style={editIconStyles}
            >
                <CreateRoundedIcon
                    fontSize={iconSize}
                    color={iconColor}
                    style={iconStyles}
                />
            </div>
        </div>
    );
}

EditorIconWrapper.propTypes = {
    children: PropTypes.node.isRequired,
    onEdit: PropTypes.func.isRequired,
    iconSize: PropTypes.oneOf(['small', 'medium', 'large']),
    iconColor: PropTypes.string,
    disabled: PropTypes.bool,
    editEnabled: PropTypes.bool
};

export default EditorIconWrapper;